"use client";
import { LeftOutlined, LoadingOutlined, ReloadOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Layout, notification, Radio } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { expandFromRawCode, ViewType } from "react-diff-view";
import { TurnEventType } from "../data/SolverInterfaceEvent";
import {
    ChangeSet,
    getChanges,
    SessionInfo,
    useAddChangesComment,
    useChangesComments,
    useRemoveChangesComment,
    useSession,
    useTurns,
} from "../data/SolverSession";
import ChangedFileTree from "./ChangedFileTree";
import ImmutableDiffCard from "./ImmutableDiffCard";
import { ChangeSetSummary, Comment, FileInfo, getRelevantPath } from "./Utils";
import JSZip from "jszip";

import "./SharedSider.scss";
import "./ChangesView.scss";

const { Sider, Content } = Layout;

interface ChangesViewProps {
    restoreScrollPosition: () => void;
}

const getReviewChanges = async (sessionInfo: SessionInfo): Promise<ChangeSet & { timestamp: number }> => {
    const changes = await getChanges(sessionInfo, {
        include_preimage: true,
        include_postimage: true,
    });
    return {
        ...changes,
        timestamp: Math.floor(Date.now() / 1000),
    };
};

const ChangesView: React.FC<ChangesViewProps> = ({ restoreScrollPosition }) => {
    const [reviewChanges, setReviewChanges] = useState<(ChangeSet & { timestamp: number }) | null>(null);
    const [hasNewChanges, setHasNewChanges] = useState(false);

    // Reset hasNewChanges on mount
    useEffect(() => {
        setHasNewChanges(false);
    }, []);
    const [viewType, setViewType] = useState<ViewType>(() => {
        const storedViewType = localStorage.getItem("changesViewType");
        return (storedViewType === "split" ? "split" : "unified") as ViewType;
    });
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const session = useSession();
    const turns = useTurns();
    const changesComments = useChangesComments();
    const addChangesComment = useAddChangesComment();
    const removeChangesComment = useRemoveChangesComment();
    const contentRef = useRef<HTMLDivElement>(null);
    const fileRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

    const [api, contextHolder] = notification.useNotification();

    const getFileRef = (filePath: string) => {
        if (!fileRefs.current[filePath]) {
            fileRefs.current[filePath] = React.createRef<HTMLDivElement>();
        }
        return fileRefs.current[filePath];
    };

    useEffect(() => {
        if (!session) {
            setHasNewChanges(false);
            return;
        }
        getReviewChanges(session.getInfo()).then(setReviewChanges);
    }, [session]);

    useEffect(() => {
        // Always reset hasNewChanges if we don't have reviewChanges
        if (!reviewChanges || !turns) {
            setHasNewChanges(false);
            return;
        }

        // Look for any events containing changes that are newer than our last review
        const hasNewerChanges = turns.some((turn) =>
            turn.events.some(
                (event) =>
                    (event.event_type === TurnEventType.EDIT || event.event_type === TurnEventType.REVERT) &&
                    event.created > reviewChanges.timestamp
            )
        );

        setHasNewChanges(hasNewerChanges);
    }, [reviewChanges, turns]);

    useLayoutEffect(() => {
        restoreScrollPosition();
    }, [reviewChanges, restoreScrollPosition]);

    const expandReviewChangesFile = (file_path: string, start: number, end: number) => {
        setReviewChanges((prevReviewChanges) => {
            if (!prevReviewChanges) return null;

            const newFileInfos = prevReviewChanges.file_infos.map((fi) => {
                if (getRelevantPath(fi.fileData) === file_path) {
                    const source = Array.isArray(fi.source) ? fi.source : (fi.source || "").split("\n");
                    fi.fileData.hunks = expandFromRawCode(fi.fileData.hunks, source, start, end);
                }
                return fi;
            });

            return { ...prevReviewChanges, file_infos: newFileInfos };
        });
    };

    const buildDiffs = (reviewChanges: ChangeSet) => {
        return reviewChanges.file_infos.map((fileInfo: FileInfo, idx: number) => {
            const fileData = fileInfo.fileData;
            const filePath = getRelevantPath(fileData);
            const diffCardKey = `${fileData.oldRevision}-${fileData.newRevision}`;

            return (
                <div key={diffCardKey} ref={getFileRef(filePath)}>
                    <ImmutableDiffCard
                        fileInfo={fileInfo}
                        postimage={reviewChanges.postimages[idx]?.contents}
                        expandCodeFn={(start: number, end: number) => expandReviewChangesFile(filePath, start, end)}
                        onSubmitComment={(comment: Comment) => addChangesComment(comment)}
                        onRetractComment={(comment: Comment) => removeChangesComment(comment)}
                        submittedComments={changesComments.filter(
                            (comment) => getRelevantPath(comment.fileData) === getRelevantPath(fileData)
                        )}
                        collapsible={true}
                        viewType={viewType}
                    />
                </div>
            );
        });
    };

    const onTreeFileSelect = (filePath: string) => {
        setSelectedFile(filePath);
        const element = fileRefs.current[filePath]?.current;
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const downloadSelectedFiles = async (paths: string[]) => {
        if (!session) return;

        try {
            const zip = new JSZip();
            paths.forEach((path) => {
                const file = reviewChanges?.postimages.find((pi) => pi.file_path === path);
                if (file) {
                    zip.file(path, file.contents);
                }
            });

            const blob = await zip.generateAsync({ type: "blob" });

            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `session-${session.getInfo().session_id}-raw-files.zip`;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error);
            api.error({
                message: "Failed to download files",
            });
        }
    };

    return (
        <Layout className="changes-layout changes-view">
            {contextHolder}
            <div className="changes-sider-container">
                <Sider
                    width={275}
                    className="changes-sidebar"
                    collapsible
                    collapsed={sidebarCollapsed}
                    onCollapse={setSidebarCollapsed}
                    collapsedWidth={0}
                    trigger={
                        sidebarCollapsed ? (
                            <RightOutlined className="sider-trigger-icon" />
                        ) : (
                            <LeftOutlined className="sider-trigger-icon" />
                        )
                    }
                >
                    <ChangedFileTree
                        files={reviewChanges?.file_infos ?? []}
                        onFileSelect={onTreeFileSelect}
                        selectedFile={selectedFile}
                        onDownloadSelectedFiles={downloadSelectedFiles}
                    />
                </Sider>
            </div>
            <Content className="changes-content scrollbar scrollbar-gutter-stable" ref={contentRef}>
                {hasNewChanges && (
                    <div className="floating-refresh-button">
                        <Button
                            size="small"
                            type="primary"
                            icon={<ReloadOutlined />}
                            onClick={async () => {
                                if (!session) return;
                                try {
                                    setReviewChanges(await getReviewChanges(session.getInfo()));
                                    setHasNewChanges(false);
                                } catch {
                                    api.error({
                                        message: "Failed to refresh changes",
                                        placement: "bottomRight",
                                    });
                                }
                            }}
                        >
                            Refresh Changes
                        </Button>
                    </div>
                )}
                <div className="changes-view-header">
                    <div className="changes-view-header-group">
                        <Radio.Group
                            size="small"
                            onChange={(e) => {
                                const newViewType = e.target.value;
                                setViewType(newViewType);
                                localStorage.setItem("changesViewType", newViewType);
                            }}
                            value={viewType}
                            optionType="button"
                        >
                            <Radio.Button value="unified">Unified</Radio.Button>
                            <Radio.Button value="split">Split</Radio.Button>
                        </Radio.Group>
                        {reviewChanges && <ChangeSetSummary changeSet={reviewChanges} />}
                    </div>
                </div>
                {reviewChanges === null ? (
                    <div className="changes-loading">
                        <LoadingOutlined className="changes-loading-icon" />
                    </div>
                ) : reviewChanges.changes.length === 0 ? (
                    <div className="no-changes-visible">No changes to display.</div>
                ) : (
                    buildDiffs(reviewChanges)
                )}
            </Content>
        </Layout>
    );
};

export default ChangesView;
