"use client";
import { forwardRef, ReactNode, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { MessageType } from "./MessageType";
import ResponsiveTooltip from "./ResponsiveTooltip";

import { TurnEventType } from "../data/SolverInterfaceEvent";
import { ChangesContent } from "../data/TurnEventContent";
import "./Message.scss";

const MAX_COLLAPSED_HEIGHT = 150;

export const DATA_ATTRIBUTE_EVENT_ID = "data-event-id";
export const DATA_ATTRIBUTE_EVENT_TYPE = "data-event-type";
export const DATA_ATTRIBUTE_CHANGES_START = "data-changes-start";
export const DATA_ATTRIBUTE_CHANGES_END = "data-changes-end";

export interface MessageProps {
    content: ReactNode;
    messageType: MessageType;
    key?: string;
    collapsible?: boolean;
    collapsedThresholdPx?: number;
    eventId?: string;
    eventType?: TurnEventType;
    changesContent?: ChangesContent;
    defaultExpanded?: boolean;
}

export type MessageRefT = HTMLDivElement | null;

const Message = forwardRef<MessageRefT, MessageProps>(
    (
        {
            content,
            messageType,
            collapsible = true,
            collapsedThresholdPx = MAX_COLLAPSED_HEIGHT,
            eventId,
            eventType,
            changesContent,
            defaultExpanded = true,
        },
        forwardedRef
    ) => {
        const [isExpanded, setIsExpanded] = useState(defaultExpanded);
        const [isCollapsible, setIsCollapsible] = useState(false);
        const collapsibleContentRef = useRef<HTMLDivElement | null>(null);
        useImperativeHandle(forwardedRef, () => collapsibleContentRef.current as HTMLDivElement);

        useLayoutEffect(() => {
            const checkCollapsible = () => {
                if (collapsible && collapsibleContentRef.current) {
                    setIsCollapsible(collapsibleContentRef.current.scrollHeight > collapsedThresholdPx);
                }
            };

            checkCollapsible();

            // Create ResizeObserver to watch for content height changes
            const resizeObserver = new ResizeObserver(checkCollapsible);
            if (collapsibleContentRef.current) {
                resizeObserver.observe(collapsibleContentRef.current);
            }

            return () => resizeObserver.disconnect();
        }, [collapsible, collapsedThresholdPx]);

        const messageClass = classNames({
            message: true,
            "user-message": messageType === MessageType.USER,
            "agent-message": messageType === MessageType.AGENT,
            "message-collapsed": isCollapsible && !isExpanded,
            "message-expanded": isCollapsible && isExpanded,
        });

        const maxHeight = () => {
            if (!collapsible) return "none";

            return isExpanded ? "none" : collapsedThresholdPx;
        };

        const hover = () => {
            return isCollapsible ? "pointer" : "default";
        };

        return (
            <div
                className={messageClass}
                ref={collapsibleContentRef}
                style={{ maxHeight: maxHeight(), cursor: hover() }}
                onClick={(e) => {
                    if (!isCollapsible || document.getSelection()?.toString() !== "") {
                        return;
                    }
                    setIsExpanded((alreadyExpanded) => {
                        e.stopPropagation();
                        return !alreadyExpanded;
                    });
                }}
                {...{
                    [DATA_ATTRIBUTE_EVENT_ID]: eventId,
                    [DATA_ATTRIBUTE_EVENT_TYPE]: eventType,
                    [DATA_ATTRIBUTE_CHANGES_START]: changesContent?.start,
                    [DATA_ATTRIBUTE_CHANGES_END]: changesContent?.end,
                }}
            >
                {content}
                {isCollapsible && (
                    <ResponsiveTooltip title={isExpanded ? "Click to collapse" : "Click to expand"} placement="left">
                        <div className="message-indicator">{isExpanded ? <UpOutlined /> : <DownOutlined />}</div>
                    </ResponsiveTooltip>
                )}
            </div>
        );
    }
);

export default Message;
