import React from "react";
import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from "antd";

import { usePlatform } from "../data/PlatformContext";

export type ResponsiveTooltipProps = AntTooltipProps;

export const ResponsiveTooltip: React.FC<ResponsiveTooltipProps> = ({ children, ...tooltipProps }) => {
    const { deviceSupportsHover } = usePlatform();

    const { title, ...restOfTooltipProps } = tooltipProps;

    return (
        // An empty title will cause the tooltip to not render at all.
        <AntTooltip title={deviceSupportsHover ? tooltipProps.title : ""} {...restOfTooltipProps}>
            {children}
        </AntTooltip>
    );
};

export default ResponsiveTooltip;
