import { CheckCircleOutlined, CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Input, InputRef, Modal, notification, Space, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import "./ApiKeyManagement.scss";

export const DEFAULT_SCOPES = ["solver:read", "solver:write", "solver:push"];

export interface ApiKey {
    id: string;
    name: string;
    created_at: string;
    secret?: string;
    scopes?: string[];
    principal?: string;
    repositories?: string[] | null;
}

export interface CreateKeyRequest {
    name: string;
    scopes: string[];
}

const { Title, Paragraph } = Typography;

const handleApiError = (error: unknown) => {
    let errorMessage = "An unexpected error occurred";
    if (error && typeof error === "object" && "response" in error && error.response) {
        const response = error.response as { data?: { message?: string }; status: number };

        // Map status codes to user-friendly messages
        switch (response.status) {
            case 400:
                errorMessage = `Invalid request: ${response.data?.message || "Bad request"}`;
                break;
            case 404:
                errorMessage = "API key not found";
                break;
            case 409:
                errorMessage = "An API key with this name already exists";
                break;
            default:
                if (response.status >= 500) {
                    errorMessage = "Service is currently unavailable";
                } else {
                    errorMessage = response.data?.message || "Failed to complete request";
                }
        }
    } else if (error instanceof Error) {
        errorMessage = error.message;
    }

    return errorMessage;
};
interface CreateApiKeyModalProps {
    open: boolean;
    onClose: () => void;
    onKeyCreated: (key: ApiKey) => void;
}

const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = ({ open, onClose, onKeyCreated }) => {
    const [api, contextHolder] = notification.useNotification();
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [inputError, setInputError] = useState<string | null>(null);
    const [createdKey, setCreatedKey] = useState<ApiKey | null>(null);
    const inputRef = React.useRef<InputRef>(null);

    const handleAfterOpenChange = (visible: boolean) => {
        if (visible) {
            inputRef.current?.focus();
        } else {
            setName("");
            setInputError(null);
            setCreatedKey(null);
        }
    };

    const handleClose = () => {
        onClose();
        if (createdKey) {
            onKeyCreated(createdKey);
        }
    };
    const handleCreate = async () => {
        setInputError(null);
        if (!name.trim()) {
            setInputError("Please enter a name for the API key");
            return;
        }

        setLoading(true);
        try {
            const { data: newKey } = await solverInterfaceApiAxios.post("/apikeys", {
                name,
                scopes: DEFAULT_SCOPES,
            });
            setCreatedKey(newKey);
            setLoading(false);
        } catch (error) {
            let errorMessage = "Failed to create API key";
            if (error && typeof error === "object" && "response" in error && error.response) {
                const response = error.response as { data?: { message?: string }; status: number };
                if (response.status === 409) {
                    errorMessage = "An API key with this name already exists";
                } else {
                    errorMessage = response.data?.message || errorMessage;
                }
            }
            setInputError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={createdKey ? "API Key Created" : "Create API Key"}
                open={open}
                afterOpenChange={handleAfterOpenChange}
                onCancel={(e) => {
                    e.stopPropagation();
                    handleClose();
                }}
                zIndex={10000}
                footer={
                    createdKey
                        ? [
                              <Button
                                  key="copy"
                                  type="primary"
                                  icon={<CopyOutlined />}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      navigator.clipboard.writeText(createdKey.secret || "");
                                      api.success({
                                          message: "API key copied to clipboard",
                                          description: "You can now use this key to authenticate your API requests",
                                          placement: "bottomRight",
                                          icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
                                      });
                                  }}
                              >
                                  Copy API Key
                              </Button>,
                              <Button
                                  key="done"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      handleClose();
                                  }}
                              >
                                  Close
                              </Button>,
                          ]
                        : [
                              <Button
                                  key="cancel"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      handleClose();
                                  }}
                              >
                                  Cancel
                              </Button>,
                              <Button
                                  key="create"
                                  type="primary"
                                  loading={loading}
                                  icon={<PlusOutlined />}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      handleCreate();
                                  }}
                              >
                                  Create Key
                              </Button>,
                          ]
                }
            >
                {createdKey ? (
                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                        <Alert
                            message="Important"
                            description="Please copy your API key now. For security reasons, it won't be shown again after you close this window."
                            type="warning"
                            showIcon
                        />
                        <div className="api-key-display">
                            <Typography.Text className="api-key-text">{createdKey.secret}</Typography.Text>
                        </div>
                    </Space>
                ) : (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Input
                            ref={inputRef}
                            placeholder="Enter API key name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onPressEnter={(e) => {
                                e.stopPropagation();
                                handleCreate();
                            }}
                            status={inputError ? "error" : undefined}
                        />
                        {inputError && <Alert message={inputError} type="error" showIcon />}
                    </Space>
                )}
            </Modal>
        </>
    );
};

interface ApiKeyManagementProps {}

const ApiKeyManagement: React.FC<ApiKeyManagementProps> = () => {
    const [keys, setKeys] = useState<ApiKey[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [modal, modalHolder] = Modal.useModal();
    const [api, contextHolder] = notification.useNotification();

    const loadKeys = async () => {
        setError(null);
        try {
            const { data } = await solverInterfaceApiAxios.get("/apikeys");
            setKeys(data);
        } catch (error) {
            setError(handleApiError(error));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadKeys();
    }, []);

    const handleCreateKey = async (newKey: ApiKey) => {
        setKeys([...keys, newKey]);
    };
    const handleDeleteKey = async (keyId: string) => {
        modal.confirm({
            title: "Delete API Key",
            content: (
                <div onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}>
                    Are you sure you want to delete this API key? This action cannot be undone.
                </div>
            ),
            className: "api-key-delete-modal",
            zIndex: 10000,
            okButtonProps: {
                onMouseDown: (e) => e.stopPropagation(),
            },
            cancelButtonProps: {
                onMouseDown: (e) => e.stopPropagation(),
            },
            onOk: async () => {
                try {
                    setError(null);
                    await solverInterfaceApiAxios.delete(`/apikeys/${keyId}`);
                    setKeys(keys.filter((k) => k.id !== keyId));
                    api.success({
                        message: "API key deleted",
                        description: "The API key has been successfully deleted.",
                        placement: "bottomRight",
                    });
                } catch (error) {
                    setError(handleApiError(error));
                }
            },
        });
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "50%",
            ellipsis: {
                showTitle: false,
            },
            render: (name: string) => <Tooltip title={name}>{name}</Tooltip>,
        },
        {
            title: "Created",
            dataIndex: "created_at",
            key: "created_at",
            width: "35%",
            ellipsis: {
                showTitle: false,
            },
            render: (date: string) => new Date(date).toLocaleDateString(),
        },
        {
            title: "",
            key: "actions",
            width: "15%",
            render: (_: unknown, record: ApiKey) => (
                <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteKey(record.id);
                    }}
                />
            ),
        },
    ];

    return (
        <>
            {modalHolder}
            {contextHolder}
            <div className="api-key-management">
                <Space direction="vertical" size="middle">
                    <div className="api-key-header">
                        <Title level={5}>API Keys</Title>
                    </div>
                    <Paragraph className="api-key-description" style={{ marginBottom: 4 }}>
                        Create API keys to enable Solver integrations.
                    </Paragraph>
                    {error && (
                        <Alert
                            message="Error"
                            description={error}
                            type="error"
                            showIcon
                            closable
                            onClose={() => setError(null)}
                        />
                    )}

                    <div className="api-key-table" onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setCreateModalOpen(true)}
                            style={{ marginBottom: 16 }}
                        >
                            Create API Key
                        </Button>
                        <CreateApiKeyModal
                            open={createModalOpen}
                            onClose={() => setCreateModalOpen(false)}
                            onKeyCreated={handleCreateKey}
                        />
                        <Table
                            className="api-key-list"
                            dataSource={keys}
                            columns={columns}
                            loading={loading}
                            rowKey="id"
                            pagination={false}
                            locale={{
                                emptyText: <div className="api-key-empty-text">No API keys created yet</div>,
                            }}
                        />
                    </div>
                </Space>
            </div>
        </>
    );
};

export default ApiKeyManagement;
