import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";

export enum MemoryVisibility {
    PERSONAL = "PERSONAL",
    REPOSITORY = "REPOSITORY",
}

export interface Memory {
    id: string;
    context: string;
    memory: string;
    priority: string;
    enabled: boolean;
    repo_name: string;
    created_at: string;
    user_name: string;
    user_id: string;
    visibility: MemoryVisibility;
}

export const getMemories = async (org: string, repo: string): Promise<Memory[]> => {
    const response = await solverInterfaceApiAxios.get<Memory[]>(`/memories/${org}/${repo}?repo=${org}/${repo}`);
    return response.data;
};

export const getMemoriesByVisibility = async (
    org: string,
    repo: string,
    visibility: MemoryVisibility
): Promise<Memory[]> => {
    const response = await solverInterfaceApiAxios.get<Memory[]>(
        `/memories/${org}/${repo}?repo=${org}/${repo}&visibility=${visibility}`
    );
    return response.data;
};

export const createMemory = async (org: string, repo: string, memory: Partial<Memory>): Promise<Memory> => {
    const response = await solverInterfaceApiAxios.post<Memory>(`/memories/${org}/${repo}`, memory);
    return response.data;
};

export const updateMemory = async (
    org: string,
    repo: string,
    memoryId: string,
    memory: Partial<Memory>
): Promise<Memory> => {
    const response = await solverInterfaceApiAxios.put<Memory>(`/memories/${org}/${repo}/${memoryId}`, memory);
    return response.data;
};

export const deleteMemory = async (org: string, repo: string, memoryId: string): Promise<void> => {
    await solverInterfaceApiAxios.delete(`/memories/${org}/${repo}/${memoryId}`);
};
