export enum AppMenuState {
    None = "none",
    Execution = "execution",
    Subscription = "subscription",
    ApiIntegrations = "api-integrations",
    Settings = "settings",
    Memories = "memories",
}

export interface AppMenuStateContextType {
    isAppMenuOpen: boolean;
    appMenuState: AppMenuState;
    setIsAppMenuOpen: (open: boolean) => void;
    setAppMenuState: (tab: AppMenuState) => void;
    openExecutionSettings: () => void;
}

import React, { createContext, useContext, useState } from "react";

const AppMenuStateContext = createContext<AppMenuStateContextType | undefined>(undefined);

export const AppMenuStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAppMenuOpen, setIsAppMenuOpen] = useState(false);
    const [appMenuState, setAppMenuState] = useState(AppMenuState.None);

    const openExecutionSettings = () => {
        setAppMenuState(AppMenuState.Execution);
        setIsAppMenuOpen(true);
    };

    return (
        <AppMenuStateContext.Provider
            value={{ isAppMenuOpen, appMenuState, setIsAppMenuOpen, setAppMenuState, openExecutionSettings }}
        >
            {children}
        </AppMenuStateContext.Provider>
    );
};

export const useAppMenuState = () => {
    const context = useContext(AppMenuStateContext);
    if (context === undefined) {
        throw new Error("useAppMenuState must be used within a AppMenuStateProvider");
    }
    return context;
};
