import React from "react";

import { Modal } from "antd";

import NewRepoWizard from "./NewRepoWizard";

import { AuthType } from "../data/User";

import "./NewRepoModal.scss";

interface NewRepoModalProps {
    open: boolean;
    onClose: () => void;
    authType: AuthType;
}

const NewRepoModal: React.FC<NewRepoModalProps> = ({ open, onClose, authType }) => {
    return (
        <Modal
            title={"Connect with GitHub"}
            open={open}
            onCancel={onClose}
            footer={null}
            width={700}
            className="new-repo-modal"
        >
            <NewRepoWizard authType={authType} />
        </Modal>
    );
};

export default NewRepoModal;
