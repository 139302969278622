import React, { createContext, useEffect } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";
import { SessionInfo, useLoadSession } from "./SolverSession";
import { Repo } from "./Repos";

interface AppPath {
    repo: string | undefined;
    session: string | undefined;
    signUpToken: string | undefined;
    signUpTokenFailure: boolean;
}

const parseAppPath = (path: string, search: string): AppPath => {
    const parts = path.split("/").filter((part) => part.length > 0);

    const searchParams = new URLSearchParams(search);

    const signUpToken = searchParams.get("sign_up_token") || undefined;
    const signUpTokenFailure = searchParams.get("sign_up_token_failure") === "true";

    let repo = undefined;
    if (parts.length > 0) {
        if (parts.length > 1) {
            repo = `${parts[0]}/${parts[1]}`;
        } else {
            repo = parts[0];
        }
    }

    const session = parts.length > 2 ? parts[2] : undefined;

    return { repo, session, signUpToken, signUpTokenFailure };
};

interface NavigationContextType {}

const nullNavigationContext: NavigationContextType = {};

const NavigationContext = createContext<NavigationContextType>(nullNavigationContext);

const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { repos, setActiveRepo, loadingAdditionalRepos, onRepoNotFound } = useSolverInterfaceContext();
    const loadSession = useLoadSession();

    useEffect(() => {
        const onPopState = () => {
            const pathConstituents = parseAppPath(document.location.pathname, document.location.search);

            const repoFullName = pathConstituents.repo;

            if (loadingAdditionalRepos) return;

            const repo = repos.find((repo: Repo) => repo.full_name === repoFullName);
            if (repo) {
                setActiveRepo(repo, NavigationBehavior.NONE);

                const sessionInfo: SessionInfo | undefined = pathConstituents.session
                    ? {
                          org: repo.org,
                          repo: repo.name,
                          session_id: pathConstituents.session,
                      }
                    : undefined;

                loadSession(sessionInfo, NavigationBehavior.NONE);
            } else if (repoFullName !== undefined) {
                onRepoNotFound(repoFullName);
            }
        };

        window.addEventListener("popstate", onPopState);
        return () => {
            window.removeEventListener("popstate", onPopState);
        };
    }, [repos]);

    return <NavigationContext.Provider value={{}}>{children}</NavigationContext.Provider>;
};

const useNavigationContext = () => {
    return React.useContext(NavigationContext);
};

enum NavigationBehavior {
    NONE, // Do not change the URL
    PUSH, // Push a new URL to the history
    REPLACE, // Replace the current URL in the history
}

const navigateToRepo = (repo: string | undefined, navigationBehavior: NavigationBehavior) => {
    // TODO: in these functions, should we do nothing if the URL we would go to is the same as the current URL?

    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", `${window.origin}/${repo}`);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", `${window.origin}/${repo}`);
            break;
    }
};

const navigateToSession = (session_id: string | undefined, navigationBehavior: NavigationBehavior) => {
    const pathConstituents: AppPath = parseAppPath(window.location.pathname, document.location.search);

    if (session_id === undefined) {
        navigateToRepo(pathConstituents.repo, navigationBehavior);
        return;
    }

    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", `${window.origin}/${pathConstituents.repo}/${session_id}`);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", `${window.origin}/${pathConstituents.repo}/${session_id}`);
            break;
    }
};

const navigateToRoot = (navigationBehavior: NavigationBehavior) => {
    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", window.origin);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", window.origin);
            break;
    }
};

export type { NavigationContextType, AppPath };

export {
    NavigationBehavior,
    parseAppPath,
    NavigationProvider,
    useNavigationContext,
    navigateToRepo,
    navigateToSession,
    navigateToRoot,
};
