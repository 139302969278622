import React, { useEffect, useRef, useState } from "react";

import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Layout, Modal, notification, Radio } from "antd";

import classNames from "classnames";
import html2canvas from "html2canvas";

import ActiveSessionControls from "./ActiveSessionControls";
import ActiveSessionTitle from "./ActiveSessionTitle";
import ChangesView from "./ChangesView";
import Chat from "./Chat";
import NaturalLanguageInput from "./NaturalLanguageInput";
import PromptSuggestionChips from "./PromptSuggestionChips";
import ReportModal from "./ReportModal";
import { PlanningAnswer } from "./tech-plan/ApiTypes";
import { isPlanningSession, PlanningView } from "./tech-plan/PlanningView";

import Features from "../data/Features";
import { NavigationBehavior } from "../data/Navigation";
import { getPromptSuggestions, PromptSuggestion } from "../data/Repos";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import {
    createSession,
    LoadingSessionState,
    sessionIsLoading,
    SessionStatus,
    Turn,
    useLoadingSessionState,
    useLoadSession,
    useRefreshTurns,
    useSession,
    useSessionStatus,
    useTurns,
    useUpdateSessionTitle,
} from "../data/SolverSession";

const { Content } = Layout;

import "../responsive.scss";
import "./ActiveSession.scss";

interface ActiveSessionProps {}

const ActiveSession: React.FC<ActiveSessionProps> = () => {
    const { activeRepo, currentUser, repos } = useSolverInterfaceContext();
    const session = useSession();
    const sessionStatus = useSessionStatus();
    const turns = useTurns();
    const loadingSessionState = useLoadingSessionState();
    const refreshTurns = useRefreshTurns();
    const updateSessionTitle = useUpdateSessionTitle();
    const loadSession = useLoadSession();
    const [api, contextHolder] = notification.useNotification();

    const sessionContentRef = useRef<HTMLDivElement>(null);
    const nlInputContainerRef = useRef<HTMLDivElement>(null);

    const [modal, modalContextHolder] = Modal.useModal();

    const [viewMode, setViewMode] = useState<"chat" | "changes" | "photo" | "planning">("chat");
    const [sessionType, setSessionType] = useState<"planning" | "solving" | undefined>(undefined);
    // TODO: When we add navigation, we should add the scroll position to the state
    // object in history.pushState calls. This way it will be preserved in between active
    // sessions.
    const [chatScrollPosition, setChatScrollPosition] = useState<number>(0);
    const [changesScrollPosition, setChangesScrollPosition] = useState<number>(0);
    const [promptSuggestions, setPromptSuggestions] = useState<PromptSuggestion[]>([]);
    const [planningAnswers, setPlanningAnswers] = useState<PlanningAnswer[]>([]);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [editingTurn, setEditingTurn] = useState<Turn | null>(null);

    useEffect(() => {
        if (!Features.isProjectsUIEnabled() || !session || !turns) {
            return;
        }

        isPlanningSession(session!.getInfo()).then((isPlanning) => {
            if (isPlanning) {
                setSessionType("planning");
                setViewMode("planning");
            } else {
                setSessionType("solving");
            }
        });
    }, [turns.length, session]);

    // Reset viewMode to "chat" when the active session changes
    useEffect(() => {
        setViewMode("chat");
        setChatScrollPosition(0);
        setChangesScrollPosition(0);
    }, [session?.session_id]);
    // Handle clicks on grayed-out elements when editing
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (editingTurn && nlInputContainerRef.current) {
                const target = event.target as HTMLElement;
                // Ignore clicks on the cancel button
                if (target.closest(".edit-button.cancel-mode")) {
                    return;
                }
                // Check if click is on a grayed-out message group or the session content
                const isClickOnGrayedOut = target.closest(".grayed-out") !== null;
                const isClickOnSessionContent = sessionContentRef.current === target;
                // Cancel if clicking on grayed-out elements or directly on the session content
                if (isClickOnGrayedOut || isClickOnSessionContent) {
                    setEditingTurn(null);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [editingTurn]);

    // Global escape key handler
    useEffect(() => {
        function handleKeyDown(e: KeyboardEvent) {
            // If ESC is pressed and we are in edit mode, exit edit mode
            if (e.key === "Escape" && editingTurn) {
                setEditingTurn(null);
            }
        }

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [editingTurn]);

    useEffect(() => {
        if (sessionStatus === SessionStatus.SOLVING || sessionStatus === SessionStatus.PENDING) {
            setViewMode("chat");
        }
    }, [sessionStatus]);

    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            if (e.shiftKey && (e.metaKey || e.ctrlKey) && e.key === "p") {
                setViewMode("photo");
                // The body's overflow is normally set to hidden due to Safari adding
                // 1 pixel sometimes. We need to set it to allow scrolling in photo mode
                document.getElementsByTagName("body")[0].style.overflow = "scroll";
            }
        });

        return () => {
            window.removeEventListener("keydown", (e) => {
                if (e.shiftKey && (e.metaKey || e.ctrlKey) && e.key === "p") {
                    setViewMode("photo");
                }
            });
        };
    }, []);

    useEffect(() => {
        setReportModalOpen(false);
        setEditingTurn(null);
    }, [session?.session_id]);

    // Fetch prompt suggestions when the active repo changes
    useEffect(() => {
        if (activeRepo && (!session || turns.length === 0)) {
            getPromptSuggestions(activeRepo.org, activeRepo.name)
                .then((suggestions) => {
                    setPromptSuggestions(suggestions);
                })
                .catch(() => {
                    setPromptSuggestions([]);
                });
        } else {
            setPromptSuggestions([]);
        }
    }, [session, turns, activeRepo]);

    const solveSuggestion = (suggestion: string) => {
        if (!activeRepo) {
            api.error({
                message: "Cannot create session",
                description: "Repository information is incomplete.",
                placement: "bottomRight",
            });
            return;
        }
        // Create a new session with the suggestion as pending NL text
        createSession(activeRepo.org, activeRepo.name, activeRepo.default_branch, suggestion).then((result) => {
            if (result.session) {
                const sessionInfo = result.session.getInfo();
                // Load and navigate to the new session
                loadSession(sessionInfo, NavigationBehavior.PUSH);
                // The NL text will be set when the session loads via pending_nl_text
            }
        });
    };

    const handleScreenshot = () => {
        html2canvas(document.getElementById("root") as HTMLElement, { useCORS: true }).then((canvas) => {
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = "screenshot.png";
            link.click();
        });
    };

    const sessionContent = () => {
        switch (viewMode) {
            case "planning":
                return (
                    <PlanningView isLoading={sessionIsLoading(sessionStatus)} onAnswersChange={setPlanningAnswers} />
                );
            case "changes":
                return (
                    <ChangesView
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, changesScrollPosition);
                        }}
                    />
                );
            case "chat":
            case "photo":
            default:
                return (
                    <Chat
                        ref={sessionContentRef}
                        editingTurn={editingTurn}
                        setEditingTurn={setEditingTurn}
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, chatScrollPosition);
                        }}
                        onShowChangesView={() => setViewMode("changes")}
                        onReportIssue={() => setReportModalOpen(true)}
                    />
                );
        }
    };

    if (repos.length === 0) {
        return <Content className="no-active-session" />;
    }

    const buildWelcomeMessage = () => {
        const welcomeMessageContainerClasses = classNames({
            "welcome-message-container": true,
            "welcome-message-container-small": promptSuggestions.length > 0,
        });

        return (
            <Layout className="session-chat-layout">
                <Content className="no-active-session">
                    <div className="new-session-message">
                        <div className="new-session-solver-logo"></div>
                        <div className={welcomeMessageContainerClasses}>
                            <p className="welcome-message-line">Describe your task</p>
                            <p className="welcome-message-line">Start Solving</p>
                            {promptSuggestions.length > 0 && (
                                <p className="welcome-message-line">A few ideas to get started:</p>
                            )}
                        </div>
                        <PromptSuggestionChips
                            promptSuggestions={promptSuggestions}
                            onClick={(suggestion) => solveSuggestion(suggestion)}
                        />
                    </div>
                </Content>
                <div className="input-container" id="tour-task-input">
                    <NaturalLanguageInput
                        notification={api}
                        editingTurn={editingTurn}
                        onCancelEditingTurn={() => setEditingTurn(null)}
                        sessionType={undefined}
                    />
                </div>
                {contextHolder}
            </Layout>
        );
    };

    const buildSessionContent = () => {
        if (!session) {
            return null;
        }

        const layoutClass = classNames({
            "session-full-height-layout": viewMode === "photo",
            "session-chat-layout": true,
        });

        const sessionTitle = session ? session.title : "New Session";

        return (
            <Layout className={layoutClass}>
                <div className="active-session-title-bar">
                    <ActiveSessionTitle
                        sessionTitle={sessionTitle}
                        editable={currentUser?.id === session.user_id}
                        onUpdateTitle={async (title) => {
                            if (session) {
                                return updateSessionTitle(title);
                            } else {
                                console.error("No active session to update title");
                                return false;
                            }
                        }}
                        notification={api}
                    />
                    <div className="active-session-controls-container">
                        <ActiveSessionControls
                            className="active-session-controls"
                            session={session}
                            exportDisabled={sessionIsLoading(sessionStatus)}
                            modificationDisabled={!session.allowModification(currentUser?.id)}
                            onReportIssue={() => setReportModalOpen(true)}
                            onPulledRemoteChanges={() => refreshTurns()}
                            notification={api}
                            modal={modal}
                        />
                        <div className="active-session-controls-divider small-screen-hidden" />
                        <span className="active-session-controls small-screen-hidden">
                            {viewMode === "photo" && (
                                <Button onClick={handleScreenshot} type="text" icon={<CameraOutlined />} />
                            )}
                            <Radio.Group
                                size="small"
                                onChange={(e) => {
                                    setViewMode((prevViewMode) => {
                                        if (prevViewMode === "chat") {
                                            setChatScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                        } else if (prevViewMode === "changes") {
                                            setChangesScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                        }

                                        return e.target.value;
                                    });
                                }}
                                value={viewMode}
                                optionType="button"
                                disabled={!session || turns.length === 0}
                            >
                                <Radio.Button className="active-session-controls-radio" value="chat">
                                    Chat
                                </Radio.Button>
                                <Radio.Button className="active-session-controls-radio" value="changes">
                                    Changes
                                </Radio.Button>
                                {Features.isProjectsUIEnabled() && sessionType === "planning" && (
                                    <Radio.Button className="active-session-controls-radio" value="planning">
                                        Planning
                                    </Radio.Button>
                                )}
                            </Radio.Group>
                        </span>
                    </div>
                </div>
                {turns.length > 0 && (
                    <div className="session-content scrollbar scrollbar-gutter-stable-both" ref={sessionContentRef}>
                        {sessionContent()}
                    </div>
                )}
                <div ref={nlInputContainerRef} className="input-container" id="tour-task-input">
                    <NaturalLanguageInput
                        notification={api}
                        editingTurn={editingTurn}
                        onCancelEditingTurn={() => setEditingTurn(null)}
                        sessionType={sessionType}
                        techPlanAnswers={planningAnswers}
                    />
                </div>
                {contextHolder}
                {modalContextHolder}
                <ReportModal
                    sessionInfo={session.getInfo()}
                    modalOpen={reportModalOpen}
                    onOpenChange={setReportModalOpen}
                    notification={api}
                />
            </Layout>
        );
    };

    switch (loadingSessionState) {
        case LoadingSessionState.ERROR:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Failed to load session</span>
                </Content>
            );
        case LoadingSessionState.NOT_FOUND:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Session not found</span>
                </Content>
            );
        case LoadingSessionState.LOADING:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">
                        <LoadingOutlined style={{ fontSize: "40px" }} />
                    </span>
                </Content>
            );
        case LoadingSessionState.DONE:
            if (!session || (session && turns.length === 0)) {
                return buildWelcomeMessage();
            } else {
                return buildSessionContent();
            }
    }
};

export default ActiveSession;
