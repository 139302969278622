"use client";
import React from "react";
import { Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import { ExecutionContent } from "../../data/TurnEventContent";
import "./Execution.scss";
import { useAppMenuState } from "../../data/AppMenuState";

import Terminal from "./Terminal";

interface ExecutionProps {
    executionContent: ExecutionContent;
}

const Execution: React.FC<ExecutionProps> = ({ executionContent }) => {
    const { openExecutionSettings } = useAppMenuState();
    return (
        <div className="execution-message-container">
            <Terminal
                command={executionContent.command}
                stdout={executionContent.stdout}
                stderr={executionContent.stderr}
                exitCode={executionContent.exit_code}
                completed={executionContent.completed}
                error_msg={executionContent.error_msg}
            />
            {!executionContent.exec_enabled && (
                <div className="message-cta-container">
                    <div className="info-cta-container">
                        <Button icon={<SettingOutlined />} onClick={openExecutionSettings} className="info-cta">
                            Configure Execution Settings
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Execution;
