import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Drawer, Layout, notification } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";

import { NavigationBehavior } from "../data/Navigation";
import "./SharedSider.scss";
import "./SessionsSider.scss";
import NewSessionButton from "./NewSessionButton";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { deleteSession, Session, useLoadSession, useSession } from "../data/SolverSession";
import SessionsList from "./SessionsList";
import { getBranches } from "../data/Repos";

import styleConstants from "../constants.module.scss";

const { Sider } = Layout;

interface SessionsSiderProps {
    drawerOpen: boolean;
    onCloseDrawer: () => void;
}

const SessionsSider: React.FC<SessionsSiderProps> = ({ drawerOpen, onCloseDrawer }) => {
    const session = useSession();
    const loadSession = useLoadSession();

    const { currentUser, activeRepo } = useSolverInterfaceContext();

    const [api, contextHolder] = notification.useNotification();
    const [collapsed, setCollapsed] = useState(false);
    const [branches, setBranches] = useState<string[]>([]);
    const [loadingBranches, setLoadingBranches] = useState<boolean>(false);

    const [needsDrawerLayout, setNeedsDrawerLayout] = useState<boolean>(false);

    useLayoutEffect(() => {
        const updateLayoutPosition = () => {
            setNeedsDrawerLayout(window.innerWidth < parseInt(styleConstants.SMALL_SCREEN_WIDTH));
            onCloseDrawer();
        };

        updateLayoutPosition();

        window.addEventListener("resize", updateLayoutPosition);

        return () => {
            window.removeEventListener("resize", updateLayoutPosition);
        };
    }, []);

    useEffect(() => {
        if (activeRepo) {
            setLoadingBranches(true);
            getBranches(activeRepo.org, activeRepo.name, 250)
                .then((fetchedBranches) => {
                    setBranches(fetchedBranches);
                })
                .catch((error) => {
                    console.error(error);

                    api.error({
                        key: "load-branches-error",
                        message: "Failed to load branches",
                        placement: "bottomRight",
                    });
                })
                .finally(() => {
                    setLoadingBranches(false);
                });
        }
    }, [activeRepo, api]);

    const buildSessionsList = () => {
        if (!activeRepo) {
            return undefined;
        } else {
            return (
                <SessionsList
                    currentUser={currentUser}
                    repo={activeRepo}
                    activeSession={session}
                    hideNewSessionButton={true}
                    onNewSession={async (sessionInfo) => {
                        onCloseDrawer();

                        await loadSession(sessionInfo, NavigationBehavior.PUSH);
                    }}
                    onSwitchSession={(s: Session) => {
                        onCloseDrawer();

                        if (s && s.session_id !== session?.session_id) {
                            return loadSession(s.getInfo(), NavigationBehavior.PUSH);
                        } else {
                            return Promise.resolve();
                        }
                    }}
                    onDeleteSession={async (sessionToDelete: Session) => {
                        if (currentUser?.id !== sessionToDelete.user_id) return;

                        const deleted = await deleteSession(sessionToDelete.getInfo());

                        if (deleted && sessionToDelete.session_id === session?.session_id) {
                            await loadSession(undefined, NavigationBehavior.PUSH);
                        }

                        if (!deleted) {
                            api.error({
                                message: "Failed to delete Session",
                                placement: "bottomRight",
                            });
                            return;
                        }
                    }}
                    notification={api}
                    branches={branches}
                    isLoading={loadingBranches}
                />
            );
        }
    };

    const buildNewSessionButton = () => {
        if (!activeRepo) {
            return null;
        }

        return (
            <NewSessionButton
                repoOrg={activeRepo.org}
                repoName={activeRepo.name}
                repo={activeRepo}
                onSessionCreated={async (sessionInfo) => {
                    onCloseDrawer();

                    await loadSession(sessionInfo, NavigationBehavior.PUSH);
                }}
                notification={api}
                branches={branches}
                isLoading={loadingBranches}
            />
        );
    };

    const buildSider = () => {
        return (
            <>
                <div className="sessions-sider-container">
                    <Sider
                        collapsible={!needsDrawerLayout}
                        collapsedWidth={needsDrawerLayout ? undefined : 40}
                        collapsed={collapsed && !needsDrawerLayout}
                        trigger={
                            needsDrawerLayout ? undefined : collapsed ? (
                                <RightOutlined className="sider-trigger-icon" />
                            ) : (
                                <LeftOutlined className="sider-trigger-icon" />
                            )
                        }
                        onCollapse={(value) => setCollapsed(value)}
                        width={320}
                        className={`sider ${collapsed && !needsDrawerLayout ? "sider-collapsed" : "sider-expanded"}`}
                    >
                        {(needsDrawerLayout || !collapsed) && buildSessionsList()}
                    </Sider>
                    <div
                        className={`new-session-button-container ${collapsed ? "collapsed" : ""}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {buildNewSessionButton()}
                    </div>
                </div>
                {contextHolder}
            </>
        );
    };

    if (needsDrawerLayout) {
        return (
            <Drawer
                placement="left"
                open={drawerOpen}
                width={320}
                headerStyle={{ height: styleConstants.APP_HEADER_HEIGHT, minHeight: styleConstants.APP_HEADER_HEIGHT }}
                bodyStyle={{ padding: 0, overflow: "hidden" }}
                className="sessions-sider-drawer"
                onClose={onCloseDrawer}
            >
                {buildSider()}
            </Drawer>
        );
    } else {
        return buildSider();
    }
};

export default SessionsSider;
