"use client";
import React, { useEffect, useState } from "react";

import { CaretDownOutlined, LoadingOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { usePlatform } from "../data/PlatformContext";
import { Button, Divider, Dropdown, Input, InputRef } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import classNames from "classnames";

import { Repo } from "../data/Repos";
import { AuthType } from "../data/User";
import RepoCard from "./RepoCard";

import "./RepoDropdown.scss";

const filterRepo = (repo: Repo, filter: string) => {
    if (filter.trim() === "") {
        return true;
    }

    return repo.name.toLowerCase().includes(filter.trim().toLowerCase());
};

interface RepoDropdownProps {
    activeRepo: Repo | undefined;
    repos: Repo[];
    onSelectRepo: (repo: Repo) => void;
    loading: boolean;
    isActivatingRepo: Repo | undefined;
    isDropdownOpen: boolean;
    setDropdownOpen: (open: boolean) => void;
    onAddRepo: () => void;
    authType: AuthType | undefined;
}

const RepoDropdown: React.FC<RepoDropdownProps> = ({
    activeRepo,
    repos,
    onSelectRepo,
    loading,
    isActivatingRepo,
    isDropdownOpen,
    setDropdownOpen,
    onAddRepo,
    authType,
}) => {
    const [repoDropdownTab, setRepoDropdownTab] = useState<"user" | "demo">("user");
    const [repoFilter, setRepoFilter] = useState<string>("");
    const inputRef = React.useRef<InputRef>(null);

    const { isTouchDevice } = usePlatform();

    // Ensure input ref is ready when dropdown opens and clear filter when closed
    useEffect(() => {
        if (isDropdownOpen && inputRef.current) {
            // Only auto-focus on non-touch devices
            if (!isTouchDevice) {
                // Use requestAnimationFrame to ensure focus works reliably
                requestAnimationFrame(() => {
                    inputRef.current?.focus();
                });
            }
        } else {
            // Clear the filter when dropdown is closed
            setRepoFilter("");
        }
    }, [isDropdownOpen, isTouchDevice]);

    const demoRepos: Repo[] = [];
    const groupedUserRepos: { [org: string]: Repo[] } = {};

    repos.forEach((repo) => {
        if (!filterRepo(repo, repoFilter)) {
            return;
        }
        if (repo.is_demo) {
            demoRepos.push(repo);
            return;
        }

        if (filterRepo(repo, repoFilter)) {
            if (!groupedUserRepos[repo.org]) {
                groupedUserRepos[repo.org] = [];
            }

            groupedUserRepos[repo.org].push(repo);
        }
    });

    for (const org in groupedUserRepos) {
        // Active repos first, then inactive, alphabetically within each group.
        groupedUserRepos[org].sort((a, b) => {
            if (a.is_activated && !b.is_activated) {
                return -1;
            } else if (!a.is_activated && b.is_activated) {
                return 1;
            }

            return a.name.localeCompare(b.name);
        });
    }

    const handleRepoFilter = (newFilter: string) => {
        setRepoFilter(newFilter);
        if (!newFilter.trim()) {
            setRepoDropdownTab("user");
            return;
        }

        const hasOnlyDemoMatches = demoRepos.length > 0 && Object.values(groupedUserRepos).flat().length === 0;

        if (hasOnlyDemoMatches) {
            setRepoDropdownTab("demo");
        }
    };

    const buildMenuItems = () => {
        if (loading) {
            return [
                {
                    icon: (
                        <div className="repo-dropdown-non-selectable-label">
                            <LoadingOutlined />
                            Loading repos...
                        </div>
                    ),
                    key: "loading",
                },
            ];
        }

        const menuItems: ItemType[] = [];
        menuItems.push({
            icon: (
                <div className="repo-dropdown-non-selectable-label">
                    <Input
                        value={repoFilter}
                        onChange={(e) => handleRepoFilter(e.target.value)}
                        ref={inputRef}
                        placeholder="Search repositories"
                        addonBefore={<SearchOutlined />}
                        allowClear
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                const allFilteredRepos = [...Object.values(groupedUserRepos).flat(), ...demoRepos];

                                if (allFilteredRepos.length === 1) {
                                    onSelectRepo(allFilteredRepos[0]);
                                }
                            }
                        }}
                    />
                </div>
            ),
            key: "filter-repos",
        });

        const userRepoCount = Object.values(groupedUserRepos).reduce((sum, repos) => sum + repos.length, 0);

        const tabs = [];
        tabs.push(
            <Button
                key="user-repo-tab"
                className={classNames({
                    "repo-dropdown-tab-button": true,
                    "repo-dropdown-tab-button-active": repoDropdownTab === "user",
                })}
                onClick={(e) => {
                    setRepoDropdownTab("user");
                    e.stopPropagation();
                }}
            >
                My Repos ({userRepoCount})
            </Button>
        );

        if (demoRepos.length > 0) {
            if (tabs.length > 0) {
                tabs.push(<Divider key="tab-divider" type="vertical" className="repo-dropdown-tabs-divider" />);
            }
            tabs.push(
                <Button
                    key="demo-repo-tab"
                    className={classNames({
                        "repo-dropdown-tab-button": true,
                        "repo-dropdown-tab-button-active": repoDropdownTab === "demo",
                    })}
                    onClick={(e) => {
                        setRepoDropdownTab("demo");
                        e.stopPropagation();
                    }}
                >
                    Demo ({demoRepos.length})
                </Button>
            );
        }

        if (tabs.length > 0) {
            menuItems.push({
                icon: <div className="repo-dropdown-tabs">{tabs}</div>,
                key: "repo-type",
                onClick: () => {},
            });
        }

        if (repoDropdownTab === "demo") {
            if (demoRepos.length === 0) {
                menuItems.push({
                    icon: <div className="repo-dropdown-non-selectable-label">No demo repos found</div>,
                    key: "no-demo-repos",
                });
            } else {
                demoRepos.forEach((repo) => {
                    menuItems.push({
                        label: repo.name,
                        key: `demo-${repo.name}`,
                        onClick: () => {
                            onSelectRepo(repo);
                        },
                    });
                });
            }
        } else {
            if (Object.keys(groupedUserRepos).length === 0) {
                menuItems.push({
                    icon: <div className="repo-dropdown-non-selectable-label">No repos found</div>,
                    key: `no-user-repos`,
                });
            } else {
                Object.entries(groupedUserRepos).forEach(([org, orgRepos], orgIndex) => {
                    if (orgIndex > 0) {
                        menuItems.push({ type: "divider" });
                    }

                    menuItems.push({
                        type: "group",
                        label: org,
                        children: orgRepos.map((repo, index) => {
                            const labelClasses = classNames("repo-dropdown-repo-label", {
                                "repo-dropdown-repo-label-inactive": !repo.is_activated,
                            });

                            return {
                                icon: (
                                    <div className={labelClasses}>
                                        {repo.name}
                                        {buildRepoBadge(repo)}
                                    </div>
                                ),
                                key: `${org}-${index}`,
                                onClick: () => {
                                    onSelectRepo(repo);
                                },
                            };
                        }),
                    });
                });
            }
        }

        if (authType) {
            if (menuItems.length > 0) {
                menuItems.push({ type: "divider" });
            }

            menuItems.push({
                icon: (
                    <div
                        className="repo-dropdown-add-repo-label"
                        onClick={() => {
                            setDropdownOpen(false);
                            onAddRepo();
                        }}
                    >
                        <PlusCircleOutlined />
                        Add a repository
                    </div>
                ),
                key: "add-repo",
            });
        }
        return menuItems;
    };

    const buildRepoBadge = (repo: Repo) => {
        if (repo.full_name === isActivatingRepo?.full_name) {
            return <LoadingOutlined />;
        } else if (!repo.is_activated) {
            return <div className="repo-dropdown-repo-label-inactive-badge">inactive</div>;
        }

        return undefined;
    };

    const buildDropdownButton = () => {
        if (!activeRepo && repos.length === 0) return null;

        const menuItems = buildMenuItems();

        return (
            <Dropdown
                menu={{
                    items: menuItems,
                }}
                open={isDropdownOpen}
                onOpenChange={setDropdownOpen}
                overlayClassName="repo-dropdown-overlay scrollbar scrollbar-gutter-stable"
                placement="bottomRight"
                className="repo-dropdown"
                trigger={["click"]}
            >
                <div>
                    {dropdownButtonContent()}
                    {menuItems && menuItems.length > 0 && <CaretDownOutlined />}
                </div>
            </Dropdown>
        );
    };

    const dropdownButtonContent = () => {
        if (activeRepo) {
            return <RepoCard repo={activeRepo} />;
        }

        return "Select a repo";
    };

    return buildDropdownButton();
};

export default RepoDropdown;
