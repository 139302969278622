"use client";
import React from "react";

import { Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { SolutionReviewContent } from "../../data/TurnEventContent";
import ResponsiveTooltip from "../ResponsiveTooltip";
import SolverMarkdown from "../SolverMarkdown";

import "./SolutionReview.scss";

interface SolutionReviewProps {
    solutionReview: SolutionReviewContent;
    onSuggestionClick: (suggestion: string) => void;
}

const SolutionReview: React.FC<SolutionReviewProps> = ({ solutionReview, onSuggestionClick }) => {
    if (solutionReview.error) {
        return null;
    }

    const taskSuggestions = Array.isArray(solutionReview.task_suggestions)
        ? solutionReview.task_suggestions.slice(0, 3)
        : [];
    const codeReviewIssues = Array.isArray(solutionReview.review_issues)
        ? solutionReview.review_issues.slice(0, 3)
        : [];
    const guidelinesSuggestions = Array.isArray(solutionReview.guidelines_suggestions)
        ? solutionReview.guidelines_suggestions.slice(0, 3)
        : [];

    if (solutionReview.init) {
        return <SolverMarkdown text={solutionReview.init_message} />;
    }

    return (
        <div className="solution-review-container">
            <ResponsiveTooltip title={solutionReview.task_summary}>
                <InfoCircleOutlined className="solution-review-icon" />
            </ResponsiveTooltip>

            <h3 className="solution-review-header">Summary</h3>
            <SolverMarkdown text={solutionReview.impl_summary} />

            {taskSuggestions.length > 0 && (
                <div className="solution-review-suggestions">
                    {taskSuggestions.map((suggestion, index) => (
                        <Button
                            key={index}
                            className="info-cta solution-review-suggestion-button"
                            onClick={() => onSuggestionClick(suggestion)}
                        >
                            <SolverMarkdown text={suggestion} />
                        </Button>
                    ))}
                </div>
            )}

            {solutionReview.review_summary && (
                <>
                    <h3 className="solution-review-header">Code Review</h3>
                    <SolverMarkdown text={solutionReview.review_summary} />

                    {codeReviewIssues.length > 0 && (
                        <div className="solution-review-suggestions">
                            {codeReviewIssues.map((suggestion, index) => (
                                <Button
                                    key={index}
                                    className="info-cta solution-review-suggestion-button"
                                    onClick={() => onSuggestionClick(suggestion)}
                                >
                                    <SolverMarkdown text={suggestion} />
                                </Button>
                            ))}
                        </div>
                    )}
                </>
            )}

            {solutionReview.guidelines_summary && (
                <>
                    <h3 className="solution-review-header">Guidelines Review</h3>
                    <SolverMarkdown text={solutionReview.guidelines_summary} />

                    {solutionReview.guidelines_suggestions.length > 0 && (
                        <div className="solution-review-suggestions">
                            {guidelinesSuggestions.map((suggestion, index) => (
                                <Button
                                    key={index}
                                    className="info-cta solution-review-suggestion-button"
                                    onClick={() => onSuggestionClick(suggestion)}
                                >
                                    <SolverMarkdown text={suggestion} />
                                </Button>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SolutionReview;
