import { SessionVisibility } from "./User";

export enum SolverInterfaceEventType {
    DISCONNECT = "disconnected",
    SESSION_CREATED = "session_created",
    SESSION_DELETED = "session_deleted",
    SESSION_STATUS = "session_status",
    SESSION_CHANGED = "session_changed",
    SESSION_VISIBILITY = "session_visibility",
    TURN_CHANGED = "turn_changed",
    TURN_EVENT = "turn_event",
}

export interface SolverInterfaceEvent {}

export interface SessionEventBase {
    session_id: string;
    org: string;
    repo: string;
}

export interface SessionCreatedEvent extends SessionEventBase {}

export interface SessionDeletedEvent extends SessionEventBase {}

export interface SessionStatusEvent extends SessionEventBase {
    status: string;
}

export interface SessionChangedEvent extends SessionEventBase {}

export interface SessionVisibilityEvent extends SessionEventBase {
    visibility: SessionVisibility;
}

export interface TurnEventBase extends SessionEventBase {
    turn_id: string;
}

export enum TurnEventType {
    SOLVER_LOG = "solver_log",
    WORKSPACE_CREATION_PROGRESS = "workspace_creation_progress",
    AGENT_THOUGHT = "agent_thought",
    TURN_CHANGES = "turn_changes",
    DOCUMENTATION = "documentation",
    CODE_COVERAGE = "coverage",
    EDIT = "edit",
    REVERT = "revert",
    PROFILE = "profile",
    RELEVANT_FILES = "relevant_files",
    LINT_ERRORS = "lint_errors",
    BLAME = "blame",
    BISECT = "bisect",
    EXECUTION = "execution",
    SUBMIT = "submit",
    RESOURCES_EXHAUSTED = "resources_exhausted",
    OPEN_FILE = "open",
    TEXT_SEARCH = "text_search",
    PROJECT_TREE = "tree",
    SOLUTION_REVIEW = "solution_review",
    PLAN = "plan",
    SOLVING_STOPPED = "solving_stopped",
    REMOTE_COMMITS = "remote_commits",
    MERGE_USER_BRANCH = "merge_user_branch",
    EXECUTION_EDIT = "execution_edit",
    SUGGEST_MEMORY_STORE = "suggest_memory_store",
    CREDITS_USED = "credits_used",
}

export interface TurnEvent extends TurnEventBase {
    event_id: string;
    event_idx: number;
    complete: boolean;
    event_type: TurnEventType;
    content: { [key: string]: string };
}
