import React, { useEffect } from "react";
import { Button, Dropdown } from "antd";
import { CaretDownOutlined, FunctionOutlined } from "@ant-design/icons";
import { SubscriptionData, useSubscriptionData } from "../data/SubscriptionContext";
import { useSessionStatus, useSessionSurcharge } from "../data/SolverSession";
import Features from "../data/Features";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import { round } from "lodash";

const EffortIndicator = ({ level = 24, variant = "light", disabled = false }) => {
    const levelToBarCount: Record<number, number> = {
        8: 1,
        16: 2,
        24: 3,
        32: 4,
        40: 5,
    };

    const filledBars = levelToBarCount[level] || 3;
    const totalBars = 5;

    return (
        <div className={`effort-indicator ${disabled ? "disabled" : ""}`}>
            {[...Array(totalBars)].map((_, i) => {
                return <div key={i} className={`effort-bar ${i < filledBars ? "filled" : "empty"} ${variant}`} />;
            })}
        </div>
    );
};

interface CreditControlsProps {
    onSolve: () => void;
    disabled?: boolean;
    buttonText: string;
}

const CreditControls: React.FC<CreditControlsProps> = ({ onSolve, disabled, buttonText }) => {
    const showCreditControls = Features.isPaymentEnabled();
    const surcharge = useSessionSurcharge();
    const sessionStatus = useSessionStatus();
    const { getNumSteps, setNumSteps, openPurchaseCreditsModal } = useSubscriptionData();

    const [isLoading, setIsLoading] = React.useState(false);
    const [subscriptionData, setSubscriptionData] = React.useState<SubscriptionData | null>(null);
    const [availableCredits, setAvailableCredits] = React.useState(0);
    const [hasSufficientCredits, setHasSufficientCredits] = React.useState(false);

    const createLabel = (steps: number, disabled: boolean) => {
        return (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <EffortIndicator level={steps} variant="dark" disabled={disabled} />
                <div>
                    Up to {steps} steps (max <span style={{ fontSize: "80%" }}>ⓢ</span>
                    {"\u2009"}
                    {round((surcharge * steps) / 100, 1)})
                </div>
            </div>
        );
    };

    const buildFooter = (surcharge: number, hasSufficientCredits: boolean, isFree: boolean) => {
        if (!hasSufficientCredits) {
            return (
                <div className="credit-controls-error">
                    Insufficient credits
                    {isFree ? (
                        <Button
                            type="link"
                            onClick={() => {
                                solverInterfaceApiAxios
                                    .get("/update_subscription", {
                                        params: { return_url: window.location.href },
                                    })
                                    .then((response) => (window.location.href = response.data.url))
                                    .catch((error) => console.log(error));
                            }}
                        >
                            Upgrade to Pro
                        </Button>
                    ) : (
                        <Button type="link" onClick={openPurchaseCreditsModal}>
                            Top up
                        </Button>
                    )}
                </div>
            );
        } else if (surcharge > 100) {
            return (
                <div className="credit-controls-info">
                    {`Additional ${(surcharge - 100).toFixed(0)}% surcharge for session length`}
                </div>
            );
        }
        return undefined;
    };

    useEffect(() => {
        if (!showCreditControls) {
            return;
        }

        setIsLoading(true);
        // TODO: I know this is 100% the wrong approach but I just can't get the data loading to all work properly.
        solverInterfaceApiAxios
            .get("/subscription_usage")
            .then((response) => {
                if (response.status === 200) {
                    setSubscriptionData(response.data);

                    const subscriptionCredits = response.data.remaining_subscription_usage || 0;
                    const usageCredits = response.data.remaining_usage_credits || 0;
                    const credits = subscriptionCredits + usageCredits;
                    const numSteps = getNumSteps();

                    if (numSteps === 0 || surcharge * numSteps > credits) {
                        // Handle the two following corner cases:
                        // 1. When the user has fallen in to the "insufficient credits" state and then has gotten out of
                        //    it either by purchasing more credits or by having their subscription refresh.
                        //    In this case, we want to set the number of steps to the maximum possible.
                        // 2. When the user has fallen below the currently set number of steps. In this case, we want to
                        //    set the number of steps to the maximum allowable.
                        const maxSteps =
                            [8, 16, 24, 32, 40].reverse().find((steps) => surcharge * steps <= credits) || 0;
                        setNumSteps(maxSteps);
                    }

                    setAvailableCredits(credits);
                    setHasSufficientCredits(credits >= surcharge * 8);
                } else {
                    setSubscriptionData(null);
                    setAvailableCredits(0);
                    setHasSufficientCredits(false);
                }
            })
            .catch((error) => console.error("Error fetching subscription data:", error))
            .finally(() => setIsLoading(false));
    }, [sessionStatus, getNumSteps, setNumSteps, surcharge]);

    return (
        <div id="tour-solve-button" className="credit-controls-wrapper">
            <Button.Group className="solve-button-group">
                <Button
                    type="primary"
                    onClick={() => onSolve()}
                    disabled={disabled || !hasSufficientCredits}
                    icon={<FunctionOutlined />}
                    className="solve-button"
                >
                    {buttonText}
                </Button>
                {showCreditControls && subscriptionData && (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: "8",
                                    label: createLabel(8, surcharge * 8 > availableCredits),
                                    disabled: surcharge * 8 > availableCredits,
                                    onClick: () => setNumSteps(8),
                                },
                                {
                                    key: "16",
                                    label: createLabel(16, surcharge * 16 > availableCredits),
                                    disabled: surcharge * 16 > availableCredits,
                                    onClick: () => setNumSteps(16),
                                },
                                {
                                    key: "24",
                                    label: createLabel(24, surcharge * 24 > availableCredits),
                                    disabled: surcharge * 4 > availableCredits,
                                    onClick: () => setNumSteps(24),
                                },
                                {
                                    key: "32",
                                    label: createLabel(32, surcharge * 32 > availableCredits),
                                    disabled: surcharge * 32 > availableCredits,
                                    onClick: () => setNumSteps(32),
                                },
                                {
                                    key: "40",
                                    label: createLabel(40, surcharge * 40 > availableCredits),
                                    disabled: surcharge * 40 > availableCredits,
                                    onClick: () => setNumSteps(40),
                                },
                            ],
                        }}
                        trigger={["click"]}
                        placement="topRight"
                        disabled={disabled || !hasSufficientCredits}
                    >
                        <Button type="primary" className="steps-dropdown-trigger">
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <EffortIndicator
                                    level={hasSufficientCredits ? getNumSteps() : 8}
                                    variant="dark"
                                    disabled={disabled}
                                />
                                <CaretDownOutlined />
                            </div>
                        </Button>
                    </Dropdown>
                )}
            </Button.Group>
            {showCreditControls &&
                subscriptionData &&
                !isLoading &&
                buildFooter(surcharge, hasSufficientCredits, subscriptionData.is_free)}
        </div>
    );
};

export default CreditControls;
