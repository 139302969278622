import { useMemo } from "react";

import { Comment, getRelevantPath, hunkToUnidiff, trimCommentHunk } from "../components/Utils";
import { PlanningAnswer } from "../components/tech-plan/ApiTypes";

export const PROMPT_MAX_LENGTH = 5_000;

export const usePrompt = (
    currentBody: string,
    comments: Comment[],
    planningAnswers: PlanningAnswer[]
): {
    promptExceedsMaxLengthMessage: string;
    // Test whether a prompt body exceeds the max length, taking into account
    // comments.
    doesTestPromptExceedMaxLength: (body: string) => boolean;
} => {
    const formattedCommentsLength = useMemo(() => {
        return Prompt.formatPrompt("", comments).length;
    }, [comments]);

    const promptLength = Prompt.computePromptLength(currentBody.length, formattedCommentsLength, planningAnswers);

    const promptExceedsMaxLength = promptLength > PROMPT_MAX_LENGTH;

    let promptExceedsMaxLengthMessage = "";
    if (promptExceedsMaxLength) {
        promptExceedsMaxLengthMessage = `Input is too long - ${promptLength}/${PROMPT_MAX_LENGTH}.`;

        if (/```diff\s/.test(currentBody)) {
            promptExceedsMaxLengthMessage += " It looks like it may include comments.";
        } else if (comments.length > 0) {
            if (currentBody.trim() === "") {
                promptExceedsMaxLengthMessage += " This is due to comments.";
            } else {
                promptExceedsMaxLengthMessage += " This is partially due to comments.";
            }
        } else if (planningAnswers.length > 0) {
            if (currentBody.trim() === "") {
                promptExceedsMaxLengthMessage += " This is due to planning questions.";
            } else {
                promptExceedsMaxLengthMessage += " This is partially due to planning questions.";
            }
        }
    }

    const doesTestPromptExceedMaxLength = (body: string) => {
        return Prompt.computePromptLength(body.length, formattedCommentsLength, planningAnswers) > PROMPT_MAX_LENGTH;
    };

    return { promptExceedsMaxLengthMessage, doesTestPromptExceedMaxLength };
};

export class Prompt {
    private static BODY_SEPARATOR = "___\n";

    static formatPrompt = (body: string, comments: Comment[]): string => {
        if (comments.length === 0) {
            return body;
        }

        const formattedComments = comments.map((comment) => this.formatCodeCommentPrompt(comment)).join("\n");

        if (body.trim() === "") {
            return formattedComments;
        }

        return [formattedComments, prompt].join(this.BODY_SEPARATOR);
    };

    static computePromptLength = (
        bodyLength: number,
        formattedCommentsLength: number,
        planningAnswers: PlanningAnswer[]
    ): number => {
        return (
            // The prompt itself.
            bodyLength +
            // The formatted comments.
            formattedCommentsLength +
            // The separator between if there are both a prompt and comments to separate.
            (prompt.length > 0 && formattedCommentsLength > 0 ? Prompt.BODY_SEPARATOR.length : 0) +
            // The length of the planning questions/answers.
            planningAnswers.reduce((acc, answer) => acc + answer.question.length + answer.answer.length, 0)
        );
    };

    private static formatCodeCommentPrompt = (comment: Comment): string => {
        const commentLine = comment.change;
        const lineNumber = commentLine.type === "normal" ? commentLine.newLineNumber : commentLine.lineNumber;
        const trimmedHunk = trimCommentHunk(comment);

        let formatted = "";

        formatted += `\`\`\`diff\n${hunkToUnidiff(trimmedHunk, comment.fileData)}\n\`\`\`\n`;

        formatted += `**${getRelevantPath(comment.fileData)}:${lineNumber}**\n`;

        formatted += `${comment.text}\n`;

        return formatted;
    };
}
