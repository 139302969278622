import React, { useState, useCallback } from "react";
import { Space, Typography, Alert, Button } from "antd";
import { LoadingOutlined, ExpandOutlined, CompressOutlined } from "@ant-design/icons";
import { TechPlanTask, TechPlanTaskData } from "./TechPlanTask";
import { OpenQuestions } from "./OpenQuestions";
import "./TechPlan.css";

const { Title, Text } = Typography;
export interface TechPlanData {
    tasks: TechPlanTaskData[];
    open_questions: string[];
}

export interface TechPlanProps {
    data: TechPlanData;
    onQuestionAnswered: (
        taskId: string | null,
        questionIndex: number,
        question: string,
        answer: string,
        isUnanswering?: boolean
    ) => void;
    isLoading?: boolean;
    error?: string;
}

export const TechPlan: React.FC<TechPlanProps> = ({ data, onQuestionAnswered, isLoading = false, error }) => {
    // Create a map of task IDs to their summaries for dependency lookup
    const taskMap = new Map(data.tasks.map((task) => [task.id, task]));
    const [allCollapsed, setAllCollapsed] = useState(false);
    const [answeredProjectQuestions, setAnsweredProjectQuestions] = useState<Set<number>>(new Set());

    // Determine if a task should be initially expanded based on unanswered questions
    const shouldExpandTask = useCallback(
        (task: TechPlanTaskData) => {
            // If there are no open questions, keep it collapsed
            if (!task.open_questions || task.open_questions.length === 0) {
                return false;
            }
            // Otherwise, expand it when we're not in "all collapsed" state
            return !allCollapsed;
        },
        [allCollapsed]
    );

    const scrollToTask = (taskId: string) => {
        const element = document.getElementById(`task-${taskId}`);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleProjectQuestionAnswered = (
        questionIndex: number,
        question: string,
        answer: string,
        isUnanswering: boolean
    ) => {
        onQuestionAnswered(null, questionIndex, question, answer, isUnanswering);
        if (isUnanswering) {
            const newAnsweredQuestions = new Set(answeredProjectQuestions);
            newAnsweredQuestions.delete(questionIndex);
            setAnsweredProjectQuestions(newAnsweredQuestions);
        } else {
            setAnsweredProjectQuestions((prev: Set<number>) => new Set(prev).add(questionIndex));
        }
    };
    if (error) {
        return (
            <div className="tech-plan tech-plan-error">
                <Alert message="Error Loading Tech Plan" description={error} type="error" showIcon />
            </div>
        );
    }

    return (
        <div className="tech-plan">
            {isLoading && (
                <div className="tech-plan-header">
                    <Text type="secondary">
                        <LoadingOutlined style={{ marginRight: 8 }} />
                        Loading updates...
                    </Text>
                </div>
            )}

            {/* Project-level open questions */}
            {data.open_questions.length > 0 && (
                <div className="tech-plan-project-questions">
                    <Title level={3}>Open Questions</Title>
                    <OpenQuestions
                        questions={data.open_questions}
                        onQuestionAnswered={handleProjectQuestionAnswered}
                        disabled={isLoading}
                    />
                </div>
            )}

            {/* Tasks */}
            {data.tasks.length > 0 ? (
                <div className="tech-plan-tasks">
                    <div className="tech-plan-tasks-header">
                        <Title level={3}>Tasks ({data.tasks.length})</Title>
                        <Button
                            type="text"
                            icon={allCollapsed ? <ExpandOutlined /> : <CompressOutlined />}
                            onClick={() => setAllCollapsed(!allCollapsed)}
                        >
                            {allCollapsed ? "Expand all" : "Collapse all"}
                        </Button>
                    </div>
                    <Space direction="vertical" style={{ width: "100%" }} size="middle">
                        {data.tasks.map((task) => (
                            <TechPlanTask
                                key={task.id}
                                disabled={isLoading}
                                task={task}
                                onQuestionAnswered={onQuestionAnswered}
                                taskMap={taskMap}
                                onDependencyClick={scrollToTask}
                                id={`task-${task.id}`}
                                isCollapsed={allCollapsed && !shouldExpandTask(task)}
                            />
                        ))}
                    </Space>
                </div>
            ) : (
                <div className="tech-plan-tasks tech-plan-tasks-empty">
                    <Text type="secondary">No tasks defined yet</Text>
                </div>
            )}
        </div>
    );
};
