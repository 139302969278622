import React, { createContext, useContext, useEffect, useState } from "react";

interface LocalSettings {
    voiceInputEnabled: boolean;
}

interface LocalSettingsContextType {
    settings: LocalSettings;
    updateSettings: (newSettings: Partial<LocalSettings>) => void;
}

const defaultSettings: LocalSettings = {
    voiceInputEnabled: false,
};

const STORAGE_KEY = "localSettings";

const LocalSettingsContext = createContext<LocalSettingsContextType | undefined>(undefined);

export const LocalSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [settings, setSettings] = useState<LocalSettings>(() => {
        const stored = localStorage.getItem(STORAGE_KEY);
        return stored ? { ...defaultSettings, ...JSON.parse(stored) } : defaultSettings;
    });

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
    }, [settings]);

    const updateSettings = (newSettings: Partial<LocalSettings>) => {
        setSettings((prev) => ({ ...prev, ...newSettings }));
    };

    return (
        <LocalSettingsContext.Provider value={{ settings, updateSettings }}>{children}</LocalSettingsContext.Provider>
    );
};

export const useLocalSettings = () => {
    const context = useContext(LocalSettingsContext);
    if (context === undefined) {
        throw new Error("useLocalSettings must be used within a LocalSettingsProvider");
    }
    return context;
};
