import React, { createContext, useEffect, useState } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";
import {
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    SessionChangedEvent,
    SessionCreatedEvent,
    SessionDeletedEvent,
    SessionStatusEvent,
    SessionVisibilityEvent,
} from "./SolverInterfaceEvent";
import {
    SessionStatus,
    Session,
    getSessionBase,
    getSessions,
    updateSessionVisibility,
    useSession,
    useUpdateSessionStatus,
    useUpdateSession,
    sessionIsLoading,
} from "./SolverSession";
import { SessionVisibility } from "./User";

import { SolverInterfaceEventObserverHandle } from "../hooks/useStreamConnection";

const SESSIONS_PAGE_SIZE = 20;

export enum AuthorSelectionFilter {
    ALL = "All",
    MINE = "Mine",
}

export enum SessionStatusFilter {
    ALL = "All",
    READY = "Ready",
    SOLVING = "Solving",
    PENDING = "Pending",
    ARCHIVED = "Archived",
}

export enum SessionSortAttribute {
    CREATED = "created",
    LAST_MODIFIED = "last_modified",
}

export enum SessionSortOrder {
    ASCENDING = "ascending",
    DESCENDING = "descending",
}

const sessionStatusFilterToSessionStatuses = (status: SessionStatusFilter): SessionStatus[] => {
    switch (status) {
        case SessionStatusFilter.READY:
            return [SessionStatus.READY];
        case SessionStatusFilter.PENDING:
            return [SessionStatus.PENDING];
        case SessionStatusFilter.SOLVING:
            return [SessionStatus.SOLVING];
        case SessionStatusFilter.ARCHIVED:
            return [SessionStatus.ARCHIVED];
        case SessionStatusFilter.ALL:
        default:
            return Object.values(SessionStatus).filter(
                (status) =>
                    status !== SessionStatus.ARCHIVED &&
                    status !== SessionStatus.SUBMITTING_CANCEL &&
                    status !== SessionStatus.SUBMITTING_SOLVE
            );
    }
};

type SessionBrowsingContextType = {
    sessions: Session[];
    loadingSessions: boolean;
    page: number;
    haveMoreSessions: boolean;
    authorSelectionFilter: AuthorSelectionFilter;
    setAuthorSelectionFilter: (filter: AuthorSelectionFilter) => void;
    titleFilter: string;
    setTitleFilter: (filter: string) => void;
    statusFilter: SessionStatusFilter;
    setStatusFilter: (statusFilter: SessionStatusFilter) => void;
    sortAttribute: SessionSortAttribute;
    setSortAttribute: (sortAttribute: SessionSortAttribute) => void;
    sortOrder: SessionSortOrder;
    setSortOrder: (sortOrder: SessionSortOrder) => void;
    loadSessions: (org: string, repo: string) => void;
    loadMoreSessions: (org: string, repo: string) => void;
    updateVisibility: (session: Session, visibility: SessionVisibility) => Promise<boolean>;
    resetBrowsingFilters: () => void;
};

const nullSessionBrowsingContext: SessionBrowsingContextType = {
    sessions: [],
    loadingSessions: false,
    page: 0,
    haveMoreSessions: true,
    authorSelectionFilter: AuthorSelectionFilter.ALL,
    setAuthorSelectionFilter: () => {},
    titleFilter: "",
    setTitleFilter: () => {},
    statusFilter: SessionStatusFilter.ALL,
    setStatusFilter: () => {},
    sortAttribute: SessionSortAttribute.LAST_MODIFIED,
    setSortAttribute: () => {},
    sortOrder: SessionSortOrder.DESCENDING,
    setSortOrder: () => {},
    loadSessions: () => {},
    loadMoreSessions: () => {},
    updateVisibility: async () => false,
    resetBrowsingFilters: () => {},
};

const SessionBrowsingContext = createContext<SessionBrowsingContextType>(nullSessionBrowsingContext);

const SessionBrowsingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const activeSession = useSession();
    const updateActiveSessionStatus = useUpdateSessionStatus();
    const updateActiveSession = useUpdateSession();

    const [sessions, setSessions] = useState<Session[]>([]);
    const [page, setPage] = useState<number>(0);
    const [haveMoreSessions, setHaveMoreSessions] = useState<boolean>(true);
    const [loadingSessions, setLoadingSessions] = useState<boolean>(true);

    const [authorSelectionFilter, setAuthorSelectionFilter] = useState<AuthorSelectionFilter>(
        AuthorSelectionFilter.ALL
    );

    const [titleFilter, setTitleFilter] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<SessionStatusFilter>(SessionStatusFilter.ALL);
    const [authorFilters, setAuthorFilters] = useState<string[]>([]);
    const [sortAttribute, setSortAttribute] = useState<SessionSortAttribute>(SessionSortAttribute.LAST_MODIFIED);
    const [sortOrder, setSortOrder] = useState<SessionSortOrder>(SessionSortOrder.DESCENDING);

    const {
        activeRepo,
        activeRepoIsReady,
        addSolverInterfaceEventObserver,
        removeSolverInterfaceEventObserver,
        currentUser,
    } = useSolverInterfaceContext();

    useEffect(() => {
        const doLoadSessions = async () => {
            if (!activeRepo) {
                setSessions([]);
            } else {
                loadSessions(activeRepo.org, activeRepo.name);
            }
        };

        doLoadSessions();
    }, [activeRepo]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_CREATED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const SessionCreatedEvent = solverInterfaceEvent as SessionCreatedEvent;
                const session_id = (solverInterfaceEvent as SessionCreatedEvent).session_id;
                const org = SessionCreatedEvent.org;
                const repo = SessionCreatedEvent.repo;

                setSessions((prevSessions) => {
                    if (!prevSessions.map((s) => s.session_id).includes(session_id)) {
                        getSessionBase({ org, repo, session_id }).then((newSession) => {
                            setSessions((prevSessions) => [newSession, ...prevSessions]);
                        });
                    }
                    return prevSessions;
                });
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, []);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_DELETED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionId = (solverInterfaceEvent as SessionDeletedEvent).session_id;

                setSessions((prevSessions) => prevSessions.filter((s) => s.session_id !== sessionId));
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [sessions]);

    useEffect(() => {
        const updateSessionStatus = async (statusEvent: SessionStatusEvent) => {
            // Update the active session status if it matches, regardless of whether it's in the current list
            if (activeSession?.session_id === statusEvent.session_id) {
                updateActiveSessionStatus(statusEvent.status as SessionStatus);
            }

            // Only update the sessions list if the status-changed session is in our current view
            const sessionInList = sessions.find((s) => s.session_id === statusEvent.session_id);
            if (sessionInList) {
                setSessions((prevSessions) => {
                    return prevSessions.map((s) => {
                        if (s.session_id === statusEvent.session_id) {
                            return {
                                ...s,
                                status: statusEvent.status as SessionStatus,
                            };
                        } else {
                            return s;
                        }
                    });
                });
            } else if (
                sessionIsLoading(statusEvent.status as SessionStatus) &&
                activeSession &&
                activeSession.session_id === statusEvent.session_id
            ) {
                // If the active session is not among the sessions we have, but is loading, add it to the list
                const fetchedSession = await getSessionBase(activeSession.getInfo());

                setSessions((prevSessions) => {
                    if (prevSessions.find((s) => s.session_id === fetchedSession.session_id)) {
                        return prevSessions;
                    }

                    return [fetchedSession, ...prevSessions];
                });
            }
        };

        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_STATUS,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionStatusEvent = solverInterfaceEvent as SessionStatusEvent;
                updateSessionStatus(sessionStatusEvent);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [activeSession, sessions]);

    useEffect(() => {
        const updateSession = async (changedEvent: SessionChangedEvent) => {
            const sessionInList = sessions.find((s) => s.session_id === changedEvent.session_id);
            const updatedActiveSession = activeSession?.session_id === changedEvent.session_id;

            if (!(sessionInList || updatedActiveSession)) {
                return;
            }

            const changedSession = await getSessionBase({
                org: changedEvent.org,
                repo: changedEvent.repo,
                session_id: changedEvent.session_id,
            });

            // Update the active session if it matches, regardless of whether it's in the current list
            if (updatedActiveSession) {
                updateActiveSession(changedSession);
            }

            // Only update the sessions list if the changed session is in our current view
            if (sessionInList) {
                setSessions((prevSessions) => {
                    return prevSessions.map((s) => {
                        if (s.session_id === changedEvent.session_id) {
                            return changedSession;
                        } else {
                            return s;
                        }
                    });
                });
            }
        };

        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_CHANGED,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionChangedEvent = solverInterfaceEvent as SessionStatusEvent;

                updateSession(sessionChangedEvent);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [activeSession, sessions]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_VISIBILITY,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const visibilityEvent = solverInterfaceEvent as SessionVisibilityEvent;
                const { session_id, org, repo, visibility } = visibilityEvent;

                const sessionInList = sessions.find((s) => s.session_id === session_id);

                if (visibility === SessionVisibility.PRIVATE) {
                    // Remove session if it becomes private and it's not our own
                    if (sessionInList && sessionInList.user_id !== currentUser?.id) {
                        setSessions((prevSessions) => prevSessions.filter((s) => s.session_id !== session_id));
                    }
                } else {
                    // For public sessions, update visibility if we have it
                    if (sessionInList) {
                        setSessions((prevSessions) =>
                            prevSessions.map((s) => (s.session_id === session_id ? { ...s, visibility } : s))
                        );
                    } else {
                        // If we don't have the session and it's now public, fetch it
                        const newSession = await getSessionBase({ org, repo, session_id });
                        setSessions((prevSessions) => [newSession, ...prevSessions]);
                    }
                }
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [sessions, currentUser]);

    const loadSessions = async (org: string, repo: string) => {
        setPage(0);
        setHaveMoreSessions(true);
        setLoadingSessions(true);

        setSessions(
            await getSessions(
                org,
                repo,
                titleFilter,
                authorSelectionFilter === AuthorSelectionFilter.ALL,
                sessionStatusFilterToSessionStatuses(statusFilter as SessionStatusFilter),
                sortAttribute,
                sortOrder,
                0,
                SESSIONS_PAGE_SIZE
            )
        );
        setLoadingSessions(false);
    };

    const loadMoreSessions = async (org: string, repo: string) => {
        if (!haveMoreSessions) {
            return;
        }

        const currentPage = page;
        setPage((prevPage) => prevPage + 1);

        setLoadingSessions(true);
        const newSessions = await getSessions(
            org,
            repo,
            titleFilter,
            authorSelectionFilter === AuthorSelectionFilter.ALL,
            sessionStatusFilterToSessionStatuses(statusFilter as SessionStatusFilter),
            sortAttribute,
            sortOrder,
            currentPage + 1,
            SESSIONS_PAGE_SIZE
        );

        if (newSessions.length === 0) {
            setHaveMoreSessions(false);
        } else {
            setSessions((prevSessions) => [...prevSessions, ...newSessions]);
        }

        setLoadingSessions(false);
    };

    // TODO: expose this as a button to clear existing filters.
    // TODO: this could take args to reset to a specific state?
    const resetBrowsingFilters = async () => {
        if (
            titleFilter === "" &&
            statusFilter === SessionStatusFilter.ALL &&
            authorFilters.length === 0 &&
            sortAttribute === SessionSortAttribute.LAST_MODIFIED &&
            sortOrder === SessionSortOrder.DESCENDING
        ) {
            return;
        }

        setTitleFilter("");
        setStatusFilter(SessionStatusFilter.ALL);
        setAuthorFilters([]);
        setSortAttribute(SessionSortAttribute.LAST_MODIFIED);
        setSortOrder(SessionSortOrder.DESCENDING);

        if (activeRepo && activeRepoIsReady) {
            loadSessions(activeRepo.org, activeRepo.name);
        }
    };

    const updateVisibility = async (session: Session, visibility: SessionVisibility) => {
        const success = await updateSessionVisibility(session.getInfo(), visibility);
        if (success) {
            setSessions((prevSessions) => {
                return prevSessions.map((s) => {
                    if (s.session_id === session.session_id) {
                        return {
                            ...s,
                            visibility,
                        };
                    } else {
                        return s;
                    }
                });
            });

            return true;
        } else {
            return false;
        }
    };

    const value = {
        sessions,
        loadingSessions,
        page,
        haveMoreSessions,
        authorSelectionFilter,
        setAuthorSelectionFilter,
        titleFilter,
        setTitleFilter,
        statusFilter,
        setStatusFilter,
        sortAttribute,
        setSortAttribute,
        sortOrder,
        setSortOrder,
        loadSessions,
        loadMoreSessions,
        updateVisibility,
        resetBrowsingFilters,
    };

    return <SessionBrowsingContext.Provider value={value}>{children}</SessionBrowsingContext.Provider>;
};

const useSessionBrowsingContext = () => {
    return React.useContext(SessionBrowsingContext);
};

export type { SessionBrowsingContextType };

export { SessionBrowsingProvider, useSessionBrowsingContext };
