import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { useLocalSettings } from "../data/LocalSettingsContext";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import "./CustomAudioRecorder.scss";

interface CustomAudioRecorderProps {
    onTranscriptionComplete: (text: string) => void;
}

export const CustomAudioRecorder: React.FC<CustomAudioRecorderProps> = ({ onTranscriptionComplete }) => {
    const { settings } = useLocalSettings();
    const [isTranscribing, setIsTranscribing] = useState(false);

    if (!settings.voiceInputEnabled) return null;

    const handleRecordingComplete = async (blob: Blob) => {
        setIsTranscribing(true);
        try {
            const formData = new FormData();
            formData.append("audio", blob, "audio.wav");
            const response = await solverInterfaceApiAxios.post("/transcribe", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data && response.data.transcription) {
                onTranscriptionComplete(response.data.transcription);
            } else {
                throw new Error("Transcription data is missing or invalid");
            }
        } catch (error: unknown) {
            console.error("Error during transcription:", error);
            let errorMessage = "An error occurred while transcribing the audio.";
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    errorMessage = `Server error: ${error.response.status}`;
                } else if (error.request) {
                    errorMessage = "No response received from the server.";
                } else {
                    errorMessage = error.message || "Unknown error occurred";
                }
            } else if (error instanceof Error) {
                errorMessage = error.message;
            }
            notification.error({
                message: "Transcription failed",
                description: errorMessage,
                placement: "bottomRight",
            });
        } finally {
            setIsTranscribing(false);
        }
    };

    return (
        <div
            className={`custom-audio-recorder ${isTranscribing ? "disabled" : ""}`}
            onClick={(e) => e.stopPropagation()}
        >
            {/* HACK: We swap the handlers for the submit and discard buttons to work around positioning limitations
                in react-audio-voice-recorder. The "discard" button (left) actually submits, while the
                "submit" button (right) actually discards. */}
            {!isTranscribing && (
                <AudioRecorder
                    onRecordingComplete={handleRecordingComplete}
                    audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                    }}
                    downloadOnSavePress={false}
                    downloadFileExtension="wav"
                    showVisualizer={true}
                    classes={{
                        AudioRecorderClass: "audio-recorder",
                        AudioRecorderStartSaveClass: "audio-recorder-start-save custom-submit-button",
                        AudioRecorderPauseResumeClass: "display-none",
                        AudioRecorderDiscardClass: "audio-recorder-discard",
                    }}
                />
            )}
            {isTranscribing && <LoadingOutlined className="transcribing-loader" />}
        </div>
    );
};

export default CustomAudioRecorder;
