import React from "react";
import solverLogo from "../images/solver_logo_reverse_transparent.png";
import solverIcon from "../images/solver_icon_reverse_transparent.png";

const SolverLogo: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <div className="solver-logo" onClick={onClick}>
            <img className="solver-logo-large" src={solverLogo} width={173} height={54} alt="Solver logo" />
            <img className="solver-logo-small" src={solverIcon} width={54} height={54} alt="Solver icon" />
        </div>
    );
};

export default SolverLogo;
