import React from "react";
import { Card, Tooltip, Typography, Button, Row, Col, Divider, notification, Input } from "antd";
import { InfoCircleOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import "./SuggestMemoryStore.scss";
import { useSolverInterfaceContext } from "../../data/SolverInterface";
import { Memory, MemoryVisibility, getMemoriesByVisibility, createMemory } from "../../data/MemoryStoreInterface";

interface SuggestMemoryStoreProps {
    context: string;
    memory: string;
    priority: string;
}

const SuggestMemoryStore: React.FC<SuggestMemoryStoreProps> = ({ context, memory, priority }) => {
    const { activeRepo, currentUser } = useSolverInterfaceContext();
    const [isAccepting, setIsAccepting] = React.useState(false);
    const [isSaved, setIsSaved] = React.useState(false);
    const [notificationApi, contextHolder] = notification.useNotification();
    const [editableContext, setEditableContext] = React.useState(context);
    const [editableMemory, setEditableMemory] = React.useState(memory);

    // Check if memory already exists on mount
    React.useEffect(() => {
        const checkExistingMemory = async () => {
            if (!activeRepo || !currentUser) return;

            try {
                const memories = await getMemoriesByVisibility(
                    activeRepo.org,
                    activeRepo.name,
                    MemoryVisibility.PERSONAL
                );
                const exists = memories.some(
                    (m: Memory) =>
                        m.context === context &&
                        m.memory === memory &&
                        m.repo_name === `${activeRepo.org}/${activeRepo.name}`
                );
                if (exists) {
                    setIsSaved(true);
                }
            } catch (error) {
                console.error("Error checking existing memory", error);
            }
        };

        checkExistingMemory();
    }, [activeRepo, currentUser, context, memory]);

    const [isEditing, setIsEditing] = React.useState(false);
    const handleAccept = React.useCallback(async () => {
        if (!activeRepo || !currentUser || isSaved) {
            return;
        }

        // Turn off edit mode before proceeding with accept
        setIsEditing(false);
        setIsAccepting(true);

        const tempMemory = {
            id: `temp-${Date.now()}`,
            context: editableContext,
            memory: editableMemory,
            priority,
            enabled: true,
            repo_name: `${activeRepo.org}/${activeRepo.name}`,
            created_at: (Date.now() / 1000).toString(),
        };

        try {
            await createMemory(activeRepo.org, activeRepo.name, {
                ...tempMemory,
                repo_name: `${activeRepo.org}/${activeRepo.name}`,
                visibility: MemoryVisibility.PERSONAL,
                user_name: `${currentUser.name}`,
            });

            setIsSaved(true);
            notificationApi.success({
                message: "Memory added",
                placement: "bottomRight",
            });
        } catch (error) {
            console.error("Error saving memory:", error);
            notificationApi.error({
                message: "Failed to save memory",
                placement: "bottomRight",
            });
        } finally {
            setIsAccepting(false);
        }
    }, [activeRepo, currentUser, editableContext, editableMemory, priority, notificationApi, isSaved]);

    return (
        <>
            {contextHolder}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 8,
                }}
            >
                <Typography.Text type="secondary">Memory Suggestion</Typography.Text>
                <Tooltip title="Solver has identified an insight for the Memory Store." placement="topRight">
                    <InfoCircleOutlined
                        style={{
                            fontSize: 16,
                            color: "rgba(255, 255, 255, 0.45)",
                        }}
                    />
                </Tooltip>
            </div>
            <Card bordered={false} style={{ cursor: "default" }}>
                <Row gutter={16}>
                    <Col span={11.5} style={{ flex: 1 }}>
                        <Typography.Title level={5} style={{ marginTop: 0 }}>
                            Remember To:
                        </Typography.Title>
                        <div style={{ position: "relative" }}>
                            {isEditing && !isSaved ? (
                                <Input.TextArea
                                    value={editableMemory}
                                    onChange={(e) => setEditableMemory(e.target.value)}
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    style={{
                                        backgroundColor: "#2a2a2a",
                                        borderColor: "#434343",
                                    }}
                                    className="editable-textarea"
                                    disabled={isSaved}
                                    maxLength={256}
                                    showCount={{
                                        formatter: ({ count, maxLength }) => `${count}/${maxLength}`,
                                    }}
                                />
                            ) : (
                                <Typography.Text>{editableMemory}</Typography.Text>
                            )}
                        </div>
                    </Col>
                    <Col span={1}>
                        <Divider type="vertical" style={{ height: "100%" }} />
                    </Col>
                    <Col span={11.5} style={{ flex: 1 }}>
                        <Typography.Title level={5} style={{ marginTop: 0 }}>
                            When:
                        </Typography.Title>
                        <div style={{ position: "relative" }}>
                            {isEditing && !isSaved ? (
                                <Input.TextArea
                                    value={editableContext}
                                    onChange={(e) => setEditableContext(e.target.value)}
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    style={{
                                        backgroundColor: "#2a2a2a",
                                        borderColor: "#434343",
                                    }}
                                    className="editable-textarea"
                                    disabled={isSaved}
                                    maxLength={256}
                                    showCount={{
                                        formatter: ({ count, maxLength }) => `${count}/${maxLength}`,
                                    }}
                                />
                            ) : (
                                <Typography.Text>{editableContext}</Typography.Text>
                            )}
                        </div>
                    </Col>
                </Row>
            </Card>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16, gap: 8 }}>
                {!isSaved && (
                    <Button
                        icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                        onClick={() => {
                            if (isEditing) {
                                // Reset editable values when canceling
                                setEditableContext(context);
                                setEditableMemory(memory);
                            }
                            setIsEditing(!isEditing);
                        }}
                        style={{
                            padding: "4px 15px",
                            height: "32px",
                            lineHeight: "24px",
                            display: "inline-flex",
                            alignItems: "center",
                            fontSize: "14px",
                        }}
                    >
                        {isEditing ? "Cancel" : "Edit"}
                    </Button>
                )}
                <Button
                    type="primary"
                    loading={isAccepting}
                    onClick={handleAccept}
                    disabled={isAccepting || isSaved || !editableContext.trim() || !editableMemory.trim()}
                    style={{
                        padding: "4px 15px",
                        height: "32px",
                        lineHeight: "24px",
                        display: "inline-flex",
                        alignItems: "center",
                        fontSize: "14px",
                    }}
                >
                    {isSaved ? "Saved" : "Accept"}
                </Button>
            </div>
        </>
    );
};

export default SuggestMemoryStore;
