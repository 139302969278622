import React from "react";
import { Button, Typography } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import { SubscriptionData } from "../data/SubscriptionContext";

const { Text } = Typography;

interface RateLimitMessageProps {
    subscriptionData: SubscriptionData | null;
    openPurchaseCreditsModal: () => void;
}

const RateLimitMessage: React.FC<RateLimitMessageProps> = ({ subscriptionData, openPurchaseCreditsModal }) => {
    if (!subscriptionData) {
        return null;
    }

    const handleUpgradeClick = () => {
        if (subscriptionData.is_free) {
            solverInterfaceApiAxios
                .get("/update_subscription", { params: { return_url: window.location.href } })
                .then((response) => (window.location.href = response.data.url))
                .catch((error) => console.log(error));
        } else {
            openPurchaseCreditsModal();
        }
    };

    return (
        <div>
            <Text>
                {subscriptionData.is_free
                    ? "You've exceeded your free tier usage quota. Upgrade to Pro for increased limits."
                    : "You've reached your current usage limit. Purchase additional usage credits to continue."}
            </Text>
            <div>
                <Button
                    type="primary"
                    icon={<CreditCardOutlined />}
                    onClick={handleUpgradeClick}
                    style={{ marginTop: 8 }}
                >
                    {subscriptionData.is_free ? "Upgrade to Pro" : "Purchase Credits"}
                </Button>
            </div>
        </div>
    );
};

export default RateLimitMessage;
