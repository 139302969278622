import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";

export type Repo = {
    full_name: string;
    org: string;
    name: string;
    icon: string;
    default_branch: string | undefined;
    allow_modify_repo_settings: boolean;
    is_demo: boolean;
    is_activated: boolean;
};

export type PromptSuggestion = {
    summary: string;
    full_prompt: string;
};

export const defaultPromptSuggestions: PromptSuggestion[] = [
    {
        summary: "Analyze repository and memorize conventions and preferences",
        full_prompt:
            "Do an extensive review of this repo's structure, code, and documentation:\n- Look for meaningful code patterns and preferences that should be preserved as memories for future coding tasks\n- Propose a diverse set of only the 5 most insightful and important memories to be saved in the Memory Store\n- Keep suggested memories to 2 short sentences for readability and explain why you are suggesting each memory",
    },
];
export type GetRepoResponse = {
    repo: Repo | undefined;
    error: number | undefined;
};

export const getRepo = async (org: string, repo: string): Promise<GetRepoResponse> => {
    return await solverInterfaceApiAxios
        .get<Repo>(`/repo/${org}/${repo}`)
        .then((response) => {
            return { repo: response.data, error: undefined };
        })
        .catch((error) => {
            if (error.response) {
                return { repo: undefined, error: error.response.status };
            } else {
                throw error;
            }
        });
};

export const getBranches = async (org: string, repo: string, limit: number): Promise<string[]> => {
    return await solverInterfaceApiAxios
        .get<string[]>(`/repo/${org}/${repo}/branches`, { params: { limit } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getPromptSuggestions = async (
    org: string,
    repo: string,
    limit: number = 5
): Promise<PromptSuggestion[]> => {
    return await solverInterfaceApiAxios
        .get<PromptSuggestion[]>(`/repo/${org}/${repo}/prompt_suggestions`, { params: { limit } })
        .then((response) => {
            // Combine default suggestions with repo-specific suggestions
            return [...defaultPromptSuggestions, ...response.data];
        })
        .catch(() => {
            // Return default suggestions even if API call fails
            return defaultPromptSuggestions;
        });
};

export type GetReposResponse = {
    repos: Repo[];
    error: number | undefined;
};

export const getRepos = async (): Promise<GetReposResponse> => {
    return await solverInterfaceApiAxios
        .get<Repo[]>(`/repos`)
        .then((response) => {
            return { repos: response.data, error: undefined };
        })
        .catch((error) => {
            if (error.response) {
                return { repos: [], error: error.response.status };
            } else {
                throw error;
            }
        });
};

export const activateRepo = async (org: string, repo: string): Promise<boolean> => {
    return await solverInterfaceApiAxios
        .patch(`/repo/${org}/${repo}/activate`)
        .then(() => {
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
};

export const pushInitialCommit = async (org: string, repo: string): Promise<void> => {
    return await solverInterfaceApiAxios
        .post(`/repo/${org}/${repo}/initial_commit`)
        .then(() => {
            return;
        })
        .catch((error) => {
            throw error;
        });
};
