"use client";
import React, { useEffect, useState } from "react";

import { MenuOutlined } from "@ant-design/icons";
import { Badge, Button, Popover } from "antd";

import { NavigationBehavior } from "../data/Navigation";
import { Repo } from "../data/Repos";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { useAppMenuState } from "../data/AppMenuState";
import { useLoadSession } from "../data/SolverSession";
import { AuthType } from "../data/User";
import AppMenu from "./AppMenu";
import NotificationModal from "./NotificationModal";
import FAQModal from "./FAQModal";
import NewRepoModal from "./NewRepoModal";
import RepoDropdown from "./RepoDropdown";
import SolverLogo from "./SolverLogo";

const LOCAL_STORAGE_KEY = "shownFAQ";

const filterRepo = (repo: Repo, filter: string) => {
    if (filter.trim() === "") {
        return true;
    }

    return repo.name.toLowerCase().includes(filter.trim().toLowerCase());
};

interface SolverHeaderProps {
    onFaqClose: () => void;
    onOpenSessionsList: () => void;
}

const SolverHeader: React.FC<SolverHeaderProps> = ({ onFaqClose, onOpenSessionsList }) => {
    const loadSession = useLoadSession();
    const {
        currentUser,
        loggedIn,
        repos,
        activeRepo,
        loadingAdditionalRepos,
        setActiveRepo,
        activateRepo,
        notifications,
    } = useSolverInterfaceContext();
    const { isAppMenuOpen, setIsAppMenuOpen } = useAppMenuState();

    const [faqOpen, setFaqOpen] = useState<boolean>(false);
    const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
    const [repoDropdownOpen, setRepoDropdownOpen] = useState<boolean>(false);
    const [repoFilter, setRepoFilter] = useState<string>("");
    const [isActivatingRepo, setIsActivatingRepo] = useState<Repo | undefined>(undefined);
    const [newRepoModalOpen, setNewRepoModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const hasLoggedInBefore = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (loggedIn && !hasLoggedInBefore) {
            setFaqOpen(true);
            localStorage.setItem(LOCAL_STORAGE_KEY, "true");
        }
    }, [loggedIn]);

    const setActiveRepoAndNavigate = async (repo: Repo) => {
        if (repo.full_name === activeRepo?.full_name) {
            setRepoDropdownOpen(false);
            return;
        }

        if (!repo.is_activated) {
            setIsActivatingRepo(repo);
            const activated = await activateRepo(repo);
            setIsActivatingRepo(undefined);

            if (!activated) {
                return;
            }
        }

        setActiveRepo(repo, NavigationBehavior.PUSH);
        loadSession(undefined, NavigationBehavior.NONE);
        setRepoDropdownOpen(false);
        setRepoFilter("");
    };

    const demoRepos: Repo[] = [];
    const groupedUserRepos: { [org: string]: Repo[] } = {};

    repos.forEach((repo) => {
        if (!filterRepo(repo, repoFilter)) {
            return;
        }
        if (repo.is_demo) {
            demoRepos.push(repo);
            return;
        }

        if (filterRepo(repo, repoFilter)) {
            if (!groupedUserRepos[repo.org]) {
                groupedUserRepos[repo.org] = [];
            }

            groupedUserRepos[repo.org].push(repo);
        }
    });

    for (const org in groupedUserRepos) {
        // Active repos first, then inactive, alphabetically within each group.
        groupedUserRepos[org].sort((a, b) => {
            if (a.is_activated && !b.is_activated) {
                return -1;
            } else if (!a.is_activated && b.is_activated) {
                return 1;
            }

            return a.name.localeCompare(b.name);
        });
    }

    const buildAppMenuButton = () => {
        return (
            <div id="tour-menu-button">
                <Popover
                    content={
                        <AppMenu
                            onOpenFaq={() => setFaqOpen(true)}
                            onOpenNotifications={() => setNotificationsOpen(true)}
                            onOpenSessionsList={onOpenSessionsList}
                            haveNotifications={notifications.length > 0}
                        />
                    }
                    open={isAppMenuOpen}
                    trigger="click"
                    arrow={false}
                    onOpenChange={setIsAppMenuOpen}
                    placement="bottomRight"
                >
                    <Button className="app-menu-trigger" onClick={() => setIsAppMenuOpen(!isAppMenuOpen)}>
                        {notifications.length > 0 ? (
                            <Badge className="notification-badge" dot={true} size="small">
                                <MenuOutlined />
                            </Badge>
                        ) : (
                            <MenuOutlined />
                        )}
                    </Button>
                </Popover>
            </div>
        );
    };

    const buildHeaderActions = () => {
        return (
            <div className="header-action-bar">
                {loggedIn && (
                    <div id="tour-repo-select">
                        <RepoDropdown
                            activeRepo={activeRepo}
                            repos={repos}
                            onSelectRepo={setActiveRepoAndNavigate}
                            loading={loadingAdditionalRepos}
                            isActivatingRepo={isActivatingRepo}
                            isDropdownOpen={repoDropdownOpen}
                            setDropdownOpen={setRepoDropdownOpen}
                            onAddRepo={() => setNewRepoModalOpen(true)}
                            authType={currentUser?.auth_type}
                        />
                    </div>
                )}

                {currentUser && buildAppMenuButton()}
            </div>
        );
    };

    return (
        <>
            <div className="solver-header">
                <SolverLogo onClick={() => loadSession(undefined, NavigationBehavior.PUSH)} />
                {buildHeaderActions()}
            </div>

            <NotificationModal
                open={notificationsOpen}
                onClose={() => setNotificationsOpen(false)}
                notifications={notifications}
            />
            <FAQModal
                open={faqOpen}
                onClose={() => {
                    setFaqOpen(false);
                    onFaqClose();
                }}
            />
            <NewRepoModal
                open={newRepoModalOpen}
                onClose={() => setNewRepoModalOpen(false)}
                authType={currentUser?.auth_type || AuthType.GitHub}
            />
        </>
    );
};

export default SolverHeader;
