"use client";
import React, { useEffect, useState } from "react";

import {
    BellOutlined,
    BuildOutlined,
    CreditCardOutlined,
    BulbOutlined,
    GithubOutlined,
    GitlabOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    PlayCircleOutlined,
    SettingOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { Badge, Button, Divider, Typography } from "antd";

import ApiIntegrationsTab from "./ApiIntegrationsTab";
import MemoryStore from "./MemoryStore";
import ExecutionEnvironmentForm from "./ExecutionEnvironmentForm";
import ExecutionImageForm from "./ExecutionImageForm";
import GenericOptionsTab from "./GenericOptionsTab";
import SubscriptionTab from "./SubscriptionTab";

import { AppMenuState, useAppMenuState } from "../data/AppMenuState";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { AuthType } from "../data/User";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import type { SubscriptionData } from "../data/SubscriptionContext";
import Features from "../data/Features";
import { usePlatform } from "../data/PlatformContext";

interface AppMenuProps {
    onOpenFaq: () => void;
    onOpenNotifications: () => void;
    onOpenSessionsList: () => void;
    haveNotifications: boolean;
}

const AppMenu: React.FC<AppMenuProps> = ({ onOpenFaq, onOpenNotifications, onOpenSessionsList, haveNotifications }) => {
    const { logout, currentUser, activeRepo } = useSolverInterfaceContext();
    const { appMenuState, setIsAppMenuOpen, setAppMenuState, openExecutionSettings } = useAppMenuState();
    const { isMobileDevice } = usePlatform();

    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | null>(null);
    const [loadingSubscriptionData, setLoadingSubscriptionData] = useState(true);

    useEffect(() => {
        if (appMenuState !== AppMenuState.Subscription) return;

        const fetchSubscriptionData = async () => {
            if (!Features.isPaymentEnabled()) return;

            setLoadingSubscriptionData(true);

            try {
                const response = await solverInterfaceApiAxios.get("/subscription_usage");
                if (response.status !== 204) {
                    setSubscriptionData(response.data);
                }
            } catch (error) {
                console.error("Error fetching subscription data:", error);
            } finally {
                setLoadingSubscriptionData(false);
            }
        };

        fetchSubscriptionData();
    }, [appMenuState]); // Refetch on opening

    const onLogout = () => {
        logout();

        setIsAppMenuOpen(false);
        setAppMenuState(AppMenuState.None);
    };

    const buildCurrentUser = () => {
        if (!currentUser) return null;

        return (
            <div className="settings-current-user-info">
                <Typography.Text strong>{currentUser.name}</Typography.Text>
                {authTypeIcon(currentUser.auth_type)}
            </div>
        );
    };

    const authTypeIcon = (authType: AuthType) => {
        switch (authType) {
            case AuthType.GitHub:
                return <GithubOutlined />;
            case AuthType.GitLab:
                return <GitlabOutlined />;
            default:
                return null;
        }
    };

    const buildSubMenuHeader = (title: string) => {
        return (
            <div className="app-menu-header">
                <Typography.Title level={5} style={{ margin: 0 }}>
                    {title}
                </Typography.Title>
                <Button
                    onClick={() => {
                        setAppMenuState(AppMenuState.None);
                    }}
                >
                    Back
                </Button>
            </div>
        );
    };

    switch (appMenuState) {
        case AppMenuState.None:
            return (
                <ul className="app-menu-list">
                    <li
                        className="app-menu-list-item"
                        onClick={() => {
                            onOpenFaq();
                            setIsAppMenuOpen(false);
                        }}
                    >
                        <QuestionCircleOutlined /> FAQ
                    </li>
                    {haveNotifications && (
                        <li
                            className="app-menu-list-item"
                            onClick={() => {
                                onOpenNotifications();
                                setIsAppMenuOpen(false);
                            }}
                        >
                            <Badge className="notification-badge" dot={true} size="small">
                                <BellOutlined />
                            </Badge>
                            Notifications
                        </li>
                    )}
                    {activeRepo && (
                        <li
                            className="app-menu-list-item app-menu-list-item-hidden"
                            onClick={() => {
                                onOpenSessionsList();
                                setIsAppMenuOpen(false);
                            }}
                        >
                            <UnorderedListOutlined /> Sessions
                        </li>
                    )}
                    <li className="app-menu-list-item" onClick={openExecutionSettings}>
                        <PlayCircleOutlined />
                        Execution
                    </li>
                    {Features.isPaymentEnabled() && (
                        <li
                            className="app-menu-list-item"
                            onClick={() => {
                                setAppMenuState(AppMenuState.Subscription);
                            }}
                        >
                            <CreditCardOutlined />
                            Subscription
                        </li>
                    )}
                    {Features.isIntegrationsEnabled() && (
                        <li
                            className="app-menu-list-item"
                            onClick={() => setAppMenuState(AppMenuState.ApiIntegrations)}
                        >
                            <BuildOutlined />
                            Integrations
                        </li>
                    )}
                    {!isMobileDevice && (
                        <li className="app-menu-list-item" onClick={() => setAppMenuState(AppMenuState.Memories)}>
                            <BulbOutlined />
                            Memories
                        </li>
                    )}
                    <li className="app-menu-list-item" onClick={() => setAppMenuState(AppMenuState.Settings)}>
                        <SettingOutlined />
                        Settings
                    </li>
                    <li className="app-menu-list-item" onClick={onLogout}>
                        <LogoutOutlined />
                        Logout
                    </li>
                </ul>
            );
        case AppMenuState.Execution:
            return (
                <div className="settings-container scrollbar scrollbar-gutter-stable-both">
                    {buildSubMenuHeader("Execution")}
                    {activeRepo ? (
                        <>
                            <ExecutionImageForm
                                org={activeRepo.org}
                                repo={activeRepo.name}
                                allowChanges={activeRepo.allow_modify_repo_settings}
                            />
                            <Divider type="horizontal" className="settings-divider" />
                            <ExecutionEnvironmentForm
                                org={activeRepo.org}
                                repo={activeRepo.name}
                                allowChanges={activeRepo.allow_modify_repo_settings}
                            />
                        </>
                    ) : (
                        "Select a repo to configure execution settings"
                    )}
                </div>
            );
        case AppMenuState.Subscription:
            return (
                <div className="settings-container scrollbar scrollbar-gutter-stable-both">
                    {buildSubMenuHeader("Subscription")}
                    <SubscriptionTab
                        loading={loadingSubscriptionData}
                        subscriptionData={subscriptionData}
                        closeAppMenuFn={() => setIsAppMenuOpen(false)}
                    />
                </div>
            );
        case AppMenuState.ApiIntegrations:
            return (
                <div className="settings-container scrollbar scrollbar-gutter-stable-both">
                    {buildSubMenuHeader("Integrations")}
                    <ApiIntegrationsTab />
                </div>
            );
        case AppMenuState.Settings:
            return (
                <div className="settings-container scrollbar scrollbar-gutter-stable-both">
                    {buildSubMenuHeader("Settings")}
                    {buildCurrentUser()}
                    <GenericOptionsTab />
                </div>
            );
        case AppMenuState.Memories:
            return (
                <MemoryStore
                    open={appMenuState === AppMenuState.Memories}
                    onClose={() => setAppMenuState(AppMenuState.None)}
                />
            );
    }
};

export default AppMenu;
