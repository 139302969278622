import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import Features from "./Features";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "antd";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";

const DEFAULT_NUM_STEPS = 24;

type SubscriptionContextType = {
    isLoading: boolean;
    getNumSteps: () => number;
    setNumSteps: (steps: number) => void;
    subscriptionData: SubscriptionData | null;
    refreshSubscriptionData: () => Promise<void>;
    openPurchaseCreditsModal: () => void;
};

const nullSubscriptionContext: SubscriptionContextType = {
    isLoading: true,
    getNumSteps: () => DEFAULT_NUM_STEPS,
    setNumSteps: () => {},
    subscriptionData: null,
    refreshSubscriptionData: async () => {},
    openPurchaseCreditsModal: () => {},
};

const STRIPE_KEY = window.env?.["REACT_APP_STRIPE_KEY"] || process.env?.["REACT_APP_STRIPE_KEY"] || "";
const stripePromise = loadStripe(STRIPE_KEY);

const CheckoutForm: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const fetchClientSecret = useCallback(async () => {
        let response = await solverInterfaceApiAxios.post("/credit_purchase", {
            quantity: 500,
            return_url: window.location.href,
        });

        return await response.data.client_secret;
    }, []);

    const options = { fetchClientSecret: fetchClientSecret, onComplete: onClose };
    return (
        <Modal open={open} onCancel={onClose} footer={null} centered>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </Modal>
    );
};

const SubscriptionContext = createContext<SubscriptionContextType>(nullSubscriptionContext);

export interface SubscriptionData {
    remaining_subscription_usage: number;
    remaining_usage_credits: number;
    subscription_name: string;
    is_free: boolean;
}

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | null>(null);
    const [numStepsState, setNumStepsState] = useState<number>(DEFAULT_NUM_STEPS);
    const [purchaseCreditsModalOpen, setPurchaseCreditsModalOpen] = useState(false);

    // Initialize numSteps from localStorage
    useEffect(() => {
        const savedSteps = localStorage.getItem("solverNumSteps");
        if (savedSteps) {
            setNumStepsState(parseInt(savedSteps));
        }
    }, []);

    const getNumSteps = () => numStepsState;

    const setNumSteps = (steps: number) => {
        setNumStepsState(steps);
        localStorage.setItem("solverNumSteps", steps.toString());
    };

    const fetchSubscriptionData = async () => {
        if (!Features.isPaymentEnabled()) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        await solverInterfaceApiAxios
            .get("/subscription_usage")
            .then((response) => {
                if (response.status === 200) {
                    setSubscriptionData(response.data);
                } else {
                    setSubscriptionData(null);
                }
            })
            .catch((error) => console.error("Error fetching subscription data:", error))
            .finally(() => setIsLoading(false));
    };

    // Initial fetch on mount
    useEffect(() => {
        fetchSubscriptionData();
    }, []);

    const value = {
        isLoading,
        getNumSteps,
        setNumSteps,
        subscriptionData,
        refreshSubscriptionData: fetchSubscriptionData,
        openPurchaseCreditsModal: () => setPurchaseCreditsModalOpen(true),
    };

    return (
        <SubscriptionContext.Provider value={value}>
            <CheckoutForm open={purchaseCreditsModalOpen} onClose={() => setPurchaseCreditsModalOpen(false)} />
            {children}
        </SubscriptionContext.Provider>
    );
};

export const useSubscriptionData = () => {
    const context = useContext(SubscriptionContext);
    if (context === undefined) {
        throw new Error("useSubscriptionData must be used within a SubscriptionProvider");
    }
    return context;
};
