import React, { useState } from "react";
import { Button, Checkbox, Modal, Typography } from "antd";

interface TermsAndConditionsModalProps {
    onAccept: () => Promise<boolean>;
    onDecline: () => void;
}

const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({ onAccept, onDecline }) => {
    const [acceptChecked, setAcceptChecked] = useState(false);
    const [isAccepting, setIsAccepting] = useState(false);
    const [acceptError, setAcceptError] = useState<string | undefined>(undefined);

    return (
        <Modal
            title="Terms and Conditions/Privacy Policy"
            open={true}
            maskClosable={false}
            closable={true}
            footer={null}
            centered
            onCancel={onDecline}
        >
            <Typography.Paragraph>
                🛡️ We treat your source code with the same sanctity as passwords. We never use it for training, never
                store it permanently, and implement strict technical safeguards to protect your IP.
            </Typography.Paragraph>
            <Typography.Paragraph>
                <Checkbox
                    className="terms-and-conditions-checkbox"
                    checked={acceptChecked}
                    onChange={() => setAcceptChecked((prev) => !prev)}
                />
                I accept the{" "}
                <a className="startup-link" target="_blank" href="https://solverai.com/terms">
                    Terms and Conditions
                </a>{" "}
                and acknowledge that I have read the{" "}
                <a className="startup-link" target="_blank" href="https://solverai.com/privacy">
                    Privacy Policy
                </a>
                .
            </Typography.Paragraph>
            <div className="terms-and-conditions-button-container">
                <Button
                    className="terms-and-conditions-button"
                    type="primary"
                    disabled={!acceptChecked}
                    loading={isAccepting}
                    onClick={async () => {
                        if (!acceptChecked) return;

                        setIsAccepting(true);
                        const acceptSucceeded = await onAccept();
                        setIsAccepting(false);

                        if (!acceptSucceeded) {
                            setAcceptError(
                                "Request failed. Please try again. If the issue persists, please contact support."
                            );
                        }
                    }}
                >
                    Continue
                </Button>
                <Button className="terms-and-conditions-button" onClick={onDecline}>
                    Decline
                </Button>
            </div>
            {acceptError && <Typography.Paragraph type="danger">{acceptError}</Typography.Paragraph>}
        </Modal>
    );
};

export default TermsAndConditionsModal;
