import React, { useEffect, useState } from "react";

import { DeleteOutlined, EditOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Switch, Typography } from "antd";
import type { DefaultOptionType } from "antd/es/select";

import ResponsiveTooltip from "./ResponsiveTooltip";

import {
    DockerExecutionSettings,
    ExecutionImageType,
    useExecutionSettingsContext,
    DefaultExecutionSettings,
    CombinedImageSettings,
    ExportedImageSettings,
} from "../data/ExecutionSettings";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import { AxiosError } from "axios";

import "./Settings.scss";

interface ExecutionImageFormProps {
    org: string;
    repo: string;
    allowChanges: boolean;
}

interface ExecutionImageFormValues {
    name: string;
    description?: string;
    is_public: boolean;
    docker_registry_url: string;
    docker_image: string;
    docker_tag: string;
    repo_mount_point?: string;
    docker_username: string;
    docker_password: string;
    shell_executable?: string;
}

const formValuesFromExecutionSettings = (settings: DockerExecutionSettings): ExecutionImageFormValues => {
    return {
        name: settings.name,
        description: settings.description || "",
        is_public: settings.is_public || false,
        docker_registry_url: settings.docker_registry_url || "",
        docker_image: settings.docker_image || "",
        docker_tag: settings.docker_tag || "",
        repo_mount_point: settings.repo_mount_point || "",
        docker_username: settings.docker_username || "",
        docker_password: settings.docker_password || "",
        shell_executable: settings.shell_executable || "",
    };
};

const ExecutionImageForm: React.FC<ExecutionImageFormProps> = ({ org, repo, allowChanges }) => {
    const { currentUser } = useSolverInterfaceContext();
    const {
        currentExecutionImage,
        updateExecutionImage,
        updatingImage,
        setUpdatingImage,
        resetExecutionImage,
        loadingImage,
        imageError,
        availableImages,
        loadingAvailableImages,
        availableImagesError,
        refreshAvailableImages,
    } = useExecutionSettingsContext();

    const [configuringCustomImage, setConfiguringCustomImage] = useState<boolean>(false);

    const [editingCustomImage, setEditingCustomImage] = useState<boolean>(false);
    const [resetingImage, setResetingImage] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<string | undefined>(undefined);
    const [form] = Form.useForm<ExecutionImageFormValues>();

    // Settings are new if we're configuring a custom image and there isn't already a custom image configured
    const settingsAreNew = configuringCustomImage && currentExecutionImage.type !== ExecutionImageType.Custom;
    const onFinishEditing = async () => {
        if (currentExecutionImage.type !== ExecutionImageType.Custom) {
            return;
        }

        try {
            await form.validateFields();
        } catch {
            // validateFields raises an exception if there are validation errors.
            return;
        }

        const existingSettings = currentExecutionImage.settings;
        const formValues = form.getFieldsValue();
        const newSettings: DockerExecutionSettings = {
            name: formValues.name || existingSettings.name,
            description: formValues.description || existingSettings.description,
            is_public: formValues.is_public ?? existingSettings.is_public,
            docker_registry_url: formValues.docker_registry_url || existingSettings.docker_registry_url || "",
            docker_image: formValues.docker_image || existingSettings.docker_image || "",
            docker_tag: formValues.docker_tag || existingSettings.docker_tag || "",
            repo_mount_point: formValues.repo_mount_point || existingSettings.repo_mount_point,
            docker_username: formValues.docker_username || existingSettings.docker_username || "",
            docker_password: formValues.docker_password || existingSettings.docker_password || "",
            shell_executable: formValues.shell_executable || existingSettings.shell_executable,
        };

        const hasChanges = JSON.stringify(newSettings) !== JSON.stringify(existingSettings);

        if (!hasChanges) {
            setEditingCustomImage(false);
            return;
        }

        await updateExecutionImage({
            type: ExecutionImageType.Custom,
            settings: newSettings,
        });

        setEditingCustomImage(false);
    };

    const resetImage = async () => {
        setResetingImage(true);
        await resetExecutionImage();
        setResetingImage(false);
        setEditingCustomImage(false);
        setConfiguringCustomImage(false);
        form.resetFields();
    };

    useEffect(() => {
        // Only set form values if we have an actual custom image
        if (currentExecutionImage.type === ExecutionImageType.Custom && !configuringCustomImage) {
            const formValues = formValuesFromExecutionSettings(currentExecutionImage.settings);
            form.setFieldsValue(formValues);
            setConfiguringCustomImage(true);
            setEditingCustomImage(false);
        }
    }, [currentExecutionImage, form, configuringCustomImage]);

    const [addImageError, setAddImageError] = useState<string>();

    const onFinishForm = async (values: ExecutionImageFormValues) => {
        setAddImageError(undefined);
        setUpdatingImage(true);
        try {
            // Add the custom image
            await solverInterfaceApiAxios.post(`${org}/${repo}/execution/image/add`, {
                name: values.name,
                description: values.description,
                is_public: values.is_public,
                docker_registry_url: values.docker_registry_url,
                docker_image: values.docker_image,
                docker_tag: values.docker_tag,
                repo_mount_point: values.repo_mount_point,
                docker_username: values.docker_username,
                docker_password: values.docker_password,
                shell_executable: values.shell_executable,
            });

            // After successful addition, refresh the images list
            await refreshAvailableImages();

            // Close the form
            setConfiguringCustomImage(false);
        } catch (error) {
            console.error("Failed to add custom image:", error);
            const axiosError = error as AxiosError<{ message: string }>;
            setAddImageError(axiosError.response?.data?.message || "Failed to add custom image. Please try again.");
        } finally {
            setUpdatingImage(false);
        }
    };

    interface SelectOption extends DefaultOptionType {
        image?: CombinedImageSettings;
    }

    const selectOptions = (): SelectOption[] => {
        const exportedImages = availableImages.filter((image) => image.image_type === "exported");
        const userImages = currentUser
            ? exportedImages.filter((image) => "user_id" in image && image.user_id === currentUser.id)
            : [];
        const publicImages = currentUser
            ? exportedImages.filter((image) => "user_id" in image && image.user_id !== currentUser.id)
            : exportedImages;
        const defaultImages = availableImages.filter((image) => image.image_type === "default");

        const options: SelectOption[] = [];

        if (userImages.length > 0) {
            options.push({
                label: "My images",
                options: userImages.map((image) => ({
                    label: (
                        <span style={{ display: "flex", alignItems: "center" }}>
                            {image.title}
                            {(image.image_type === "exported" ? image.description || image.detail : image.detail) && (
                                <ResponsiveTooltip
                                    title={
                                        image.image_type === "exported"
                                            ? image.description || image.detail
                                            : image.detail
                                    }
                                    arrow={false}
                                >
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </ResponsiveTooltip>
                            )}
                            {deletingImage === image.image_id ? (
                                <LoadingOutlined style={{ marginLeft: "auto", color: "#8c8c8c" }} />
                            ) : (
                                <DeleteOutlined
                                    style={{ marginLeft: "auto", color: "#8c8c8c", cursor: "pointer" }}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        try {
                                            setDeletingImage(image.image_id);
                                            await solverInterfaceApiAxios.delete(`execution/image/${image.image_id}`);
                                            await refreshAvailableImages();
                                        } catch (error) {
                                            console.error("Failed to delete image:", error);
                                        } finally {
                                            setDeletingImage(undefined);
                                        }
                                    }}
                                />
                            )}
                        </span>
                    ),
                    value: image.image_id,
                    image: image,
                })),
            });
        }

        if (publicImages.length > 0) {
            options.push({
                label: "Shared images",
                options: publicImages.map((image) => ({
                    label: (
                        <span style={{ display: "flex", alignItems: "center" }}>
                            {image.title}
                            {(image.image_type === "exported" ? image.description || image.detail : image.detail) && (
                                <ResponsiveTooltip
                                    title={
                                        image.image_type === "exported"
                                            ? image.description || image.detail
                                            : image.detail
                                    }
                                    arrow={false}
                                >
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </ResponsiveTooltip>
                            )}
                        </span>
                    ),
                    value: image.image_id,
                    image: image,
                })),
            });
        }

        if (defaultImages.length > 0) {
            options.push({
                label: "Default images",
                options: defaultImages.map((image) => ({
                    label: (
                        <span style={{ display: "flex", alignItems: "center" }}>
                            {image.title}
                            {image.detail && (
                                <ResponsiveTooltip title={image.detail} arrow={false}>
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </ResponsiveTooltip>
                            )}
                        </span>
                    ),
                    value: image.image_id,
                    image: image,
                })),
            });
        }

        return options;
    };

    const getCurrentImageId = () => {
        if (
            currentExecutionImage.type === ExecutionImageType.Default ||
            currentExecutionImage.type === ExecutionImageType.Exported
        ) {
            return currentExecutionImage.settings.image_id;
        }
        return undefined;
    };

    const buildDefaultList = () => {
        if (availableImagesError) {
            return <Typography.Text type="danger">{availableImagesError}</Typography.Text>;
        } else if (loadingAvailableImages) {
            return (
                <div className="execution-settings-loading">
                    Loading preconfigured images
                    <LoadingOutlined />
                </div>
            );
        }

        return (
            <div className="execution-default-image-container">
                <label className="execution-image-select-label">Images</label>
                <Select
                    className="execution-image-select"
                    style={{ width: "100%" }}
                    placeholder="Select an image"
                    loading={loadingAvailableImages}
                    disabled={!allowChanges || updatingImage}
                    onChange={(value, option) => {
                        if (value === "custom") {
                            form.resetFields();
                            setConfiguringCustomImage(true);
                            // For new custom image config, both flags need to be true
                            setEditingCustomImage(true);
                            // For new configurations, don't update the backend yet
                            // Just clear the local form state
                            form.setFieldsValue({
                                docker_registry_url: "",
                                docker_image: "",
                                docker_tag: "",
                                repo_mount_point: "",
                                docker_username: "",
                                docker_password: "",
                                shell_executable: "",
                            });
                        } else {
                            // Get the full image object from the selected option
                            const selectedOption = Array.isArray(option) ? option[0] : option;
                            const image = selectedOption?.image;
                            if (image) {
                                if (image.image_type === "exported") {
                                    updateExecutionImage({
                                        type: ExecutionImageType.Exported,
                                        settings: image as ExportedImageSettings,
                                    });
                                } else {
                                    updateExecutionImage({
                                        type: ExecutionImageType.Default,
                                        settings: image as DefaultExecutionSettings,
                                    });
                                }
                            }
                        }
                    }}
                    options={selectOptions()}
                    value={getCurrentImageId()}
                />
            </div>
        );
    };

    const buildForm = () => {
        return (
            <div className="execution-custom-image-form-container">
                <div className="execution-custom-image-header">
                    {configuringCustomImage && currentExecutionImage.type !== ExecutionImageType.Custom && (
                        <Button
                            className="execution-custom-image-back-button"
                            onClick={() => {
                                setConfiguringCustomImage(false);
                                setEditingCustomImage(false);
                                setAddImageError(undefined);
                            }}
                            disabled={!allowChanges || updatingImage}
                        >
                            Back to default images
                        </Button>
                    )}
                    <label className="execution-image-select-label">
                        Custom image
                        {!settingsAreNew && (
                            <ResponsiveTooltip title="Edit custom image settings" arrow={false}>
                                <Button
                                    className="execution-image-custom-edit-button"
                                    icon={<EditOutlined />}
                                    disabled={!allowChanges || editingCustomImage}
                                    size="small"
                                    onClick={() => setEditingCustomImage(true)}
                                />
                            </ResponsiveTooltip>
                        )}
                    </label>
                </div>
                {addImageError && (
                    <Typography.Text type="danger" style={{ display: "block", marginBottom: "16px" }}>
                        {addImageError}
                    </Typography.Text>
                )}
                <Form
                    disabled={loadingImage || !allowChanges}
                    form={form}
                    labelCol={{
                        span: 8,
                    }}
                    labelAlign="left"
                    colon={false}
                    requiredMark={false}
                    onFinish={onFinishForm}
                    autoComplete="off"
                >
                    {buildFormItem("name", "Name", false, true, "A name for your custom image")}
                    {buildFormItem(
                        "description",
                        "Description",
                        false,
                        false,
                        "A description of your custom image (optional)"
                    )}
                    <Form.Item
                        className="execution-image-form-item"
                        label="Public"
                        name="is_public"
                        tooltip="Make this image available to other users"
                        initialValue={false}
                    >
                        <Switch disabled={!allowChanges || updatingImage || (!settingsAreNew && !editingCustomImage)} />
                    </Form.Item>
                    {buildFormItem(
                        "docker_registry_url",
                        "Registry URL",
                        false,
                        true,
                        "The registry where the image is hosted. Example: docker.io. Can be left blank if the image is hosted on Docker Hub."
                    )}
                    {buildFormItem("docker_image", "Image", false, true, "The name of the image to use for execution.")}
                    {buildFormItem(
                        "docker_tag",
                        "Tag",
                        false,
                        false,
                        "A tag of the image. If not provided, the latest tag will be used."
                    )}
                    {buildFormItem(
                        "repo_mount_point",
                        "Repo Mount Point",
                        false,
                        false,
                        "The path to the repo within the docker image. If not provided, /repo will be used."
                    )}
                    {buildFormItem(
                        "shell_executable",
                        "Shell Executable",
                        false,
                        false,
                        "The shell executable to use for running commands. If not provided, '/bin/bash' will be used."
                    )}
                    {settingsAreNew && buildFormItem("docker_username", "Username", false, false)}
                    {settingsAreNew &&
                        buildFormItem(
                            "docker_password",
                            "Access Token",
                            true,
                            false,
                            "The access token to use for authentication. Consult the host's documentation for information on how to generate an access token."
                        )}
                    <Form.Item style={{ textAlign: "center", marginTop: 24 }}>
                        {currentExecutionImage.type === ExecutionImageType.Custom && editingCustomImage ? (
                            <Button type="primary" loading={updatingImage} block onClick={onFinishEditing}>
                                Update Custom Image
                            </Button>
                        ) : (
                            settingsAreNew && (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={updatingImage}
                                    disabled={updatingImage}
                                    block
                                >
                                    Add Custom Image
                                </Button>
                            )
                        )}
                    </Form.Item>
                </Form>
            </div>
        );
    };

    const buildFormItem = (key: string, label: string, password: boolean, required: boolean, tooltip?: string) => {
        const inputDisabled = !allowChanges || updatingImage || (!settingsAreNew && !editingCustomImage);

        return (
            <Form.Item
                className="execution-image-form-item"
                label={label}
                name={key}
                tooltip={tooltip}
                rules={[
                    {
                        required,
                        message: `${label} is required`,
                    },
                ]}
            >
                {password ? <Input.Password disabled={inputDisabled} /> : <Input disabled={inputDisabled} />}
            </Form.Item>
        );
    };

    if (loadingImage) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <LoadingOutlined />
            </div>
        );
    }

    return (
        <>
            <h4 className="settings-title">Image</h4>
            <p className="settings-description">
                Configure a Docker image to run and test your code. Choose a preconfigured default or provide a custom
                image to meet your project's specific requirements and dependencies.
            </p>
            {!allowChanges && (
                <p className="settings-instructions">
                    The Docker image is locked for {org}/{repo}. Contact the repository owner to make changes.
                </p>
            )}
            {imageError && <Typography.Text type="danger">{imageError}</Typography.Text>}
            {updatingImage && (
                <div className="execution-settings-loading">
                    Updating image
                    <LoadingOutlined />
                </div>
            )}
            {currentExecutionImage.type !== ExecutionImageType.Custom && !configuringCustomImage && (
                <>
                    {buildDefaultList()}
                    {allowChanges && (
                        <div style={{ marginTop: "10px" }}>
                            <Button
                                style={{
                                    marginRight: currentExecutionImage.type !== ExecutionImageType.None ? "8px" : "0",
                                }}
                                onClick={() => {
                                    form.resetFields();
                                    setConfiguringCustomImage(true);
                                    setEditingCustomImage(true);
                                    setAddImageError(undefined);
                                    form.setFieldsValue({
                                        name: "",
                                        description: "",
                                        is_public: false,
                                        docker_registry_url: "",
                                        docker_image: "",
                                        docker_tag: "",
                                        repo_mount_point: "",
                                        docker_username: "",
                                        docker_password: "",
                                        shell_executable: "",
                                    });
                                }}
                            >
                                Add custom image
                            </Button>
                            {currentExecutionImage.type !== ExecutionImageType.None && (
                                <Button
                                    className="execution-image-reset-button"
                                    onClick={resetImage}
                                    loading={resetingImage}
                                >
                                    Reset image
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )}
            {currentExecutionImage.type !== ExecutionImageType.Default && configuringCustomImage && buildForm()}
        </>
    );
};

export default ExecutionImageForm;
