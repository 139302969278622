import React from "react";
import { App, Col, Row } from "antd";
import SolverLogo from "./SolverLogo";

const MaintenancePage: React.FC = () => {
    return (
        <App>
            <Row wrap={false} align="middle" className="solver-header">
                <Col flex="auto">
                    <SolverLogo onClick={() => {}} />
                </Col>
            </Row>
            <div className="startup">
                <div className="startup-text">We're doing maintenance on our systems. Please check back later.</div>
            </div>
        </App>
    );
};

export default MaintenancePage;
