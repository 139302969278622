import { Form, Select, Switch, notification } from "antd";
import React from "react";
import { useLocalSettings } from "../data/LocalSettingsContext";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { SessionVisibility } from "../data/User";

const GenericOptionsTab: React.FC = () => {
    const { settings, updateSettings } = useLocalSettings();
    const { currentUser, updateUserDefaultSessionVisibility } = useSolverInterfaceContext();
    const [api, contextHolder] = notification.useNotification();

    const updateVisibility = async (visibility: SessionVisibility) => {
        const success = await updateUserDefaultSessionVisibility(visibility);
        if (!success) {
            api.error({
                message: "Error updating visibility",
                description: "Failed to update default session visibility.",
            });
        }
    };

    return (
        <>
            {contextHolder}
            <Form layout="vertical">
                {currentUser && (
                    <Form.Item label="Default Session Visibility">
                        <Select
                            value={currentUser.default_session_visibility}
                            onChange={updateVisibility}
                            options={[
                                { value: SessionVisibility.PRIVATE, label: "Private" },
                                { value: SessionVisibility.PUBLIC_READ_ONLY, label: "Public (Read Only)" },
                                { value: SessionVisibility.PUBLIC_READ_WRITE, label: "Public (Read/Write)" },
                            ]}
                        />
                    </Form.Item>
                )}

                <Form.Item label="Voice Input (beta)" tooltip="Speak your request to Solver">
                    <Switch
                        checked={settings.voiceInputEnabled}
                        onChange={(checked) => updateSettings({ voiceInputEnabled: checked })}
                    />
                </Form.Item>
            </Form>
        </>
    );
};

export default GenericOptionsTab;
