import { LoadingOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { TurnEventType } from "../../data/SolverInterfaceEvent";
import { ChangeSet, getChanges, FileImage, SessionInfo, useSession, useTurns } from "../../data/SolverSession";
import { TechPlan } from "./TechPlan";
import { PlanningAnswer } from "./ApiTypes";

import "./PlanningView.css";

const { Content } = Layout;

interface PlanningViewProps {
    isLoading?: boolean;
    onAnswersChange?: (answers: PlanningAnswer[]) => void;
}

const getPlanningData = async (sessionInfo: SessionInfo): Promise<ChangeSet & { timestamp: number }> => {
    const changes = await getChanges(sessionInfo, {
        include_preimage: false,
        include_postimage: true,
    });
    return {
        ...changes,
        timestamp: Math.floor(Date.now() / 1000),
    };
};

const isPlanningSession = async (sessionInfo: SessionInfo): Promise<boolean> => {
    return getPlanningData(sessionInfo).then((data) => {
        return data.postimages.some((img) => img.file_path === ".solver/tech-plan.json");
    });
};

const PlanningView: React.FC<PlanningViewProps> = ({ isLoading = false, onAnswersChange }) => {
    const [planningData, setPlanningData] = useState<(ChangeSet & { timestamp: number }) | null>(null);
    const [answers, setAnswers] = useState<PlanningAnswer[]>([]);
    const session = useSession();
    const turns = useTurns();

    useEffect(() => {
        if (!session) {
            return;
        }
        getPlanningData(session.getInfo()).then(setPlanningData);
    }, [session]);

    useEffect(() => {
        // Check if we have any new plan changes
        if (!planningData || !turns) {
            return;
        }

        const hasNewerChanges = turns.some((turn) =>
            turn.events.some(
                (event) => event.event_type === TurnEventType.EDIT && event.created > planningData.timestamp
            )
        );

        if (hasNewerChanges) {
            getPlanningData(session!.getInfo()).then(setPlanningData);
        }
    }, [planningData, turns, session]);

    // Clear answers when planning data updates and notify parent
    useEffect(() => {
        if (planningData) {
            setAnswers([]);
            onAnswersChange?.([]);
        }
    }, [planningData, onAnswersChange]);

    // Notify parent component when answers change
    useEffect(() => {
        onAnswersChange?.(answers);
    }, [answers.length, onAnswersChange]);

    const getTechPlanData = (changeSet: ChangeSet | null) => {
        if (!changeSet) {
            return { tasks: [], open_questions: [] };
        }

        const planFile = changeSet.postimages.find((img: FileImage) => img.file_path === ".solver/tech-plan.json");
        if (!planFile) {
            return { tasks: [], open_questions: [] };
        }

        try {
            return JSON.parse(planFile.contents);
        } catch (e) {
            console.error("Failed to parse tech plan JSON:", e);
            return { tasks: [], open_questions: [] };
        }
    };

    const techPlanData = useMemo(() => getTechPlanData(planningData || null), [planningData]);

    if (!session || !planningData) {
        return (
            <Content className="planning-view-content">
                <div className="planning-view-loading">
                    <LoadingOutlined />
                </div>
            </Content>
        );
    }

    return (
        <Content className="planning-view-content">
            <TechPlan
                data={techPlanData}
                onQuestionAnswered={(taskId, questionIndex, question, answer, isUnanswering) => {
                    if (isUnanswering) {
                        setAnswers((prev) =>
                            prev.filter((a) => !(a.task_id === taskId && a.question_idx === questionIndex))
                        );
                    } else {
                        setAnswers((prev) => [
                            ...prev.filter((a) => !(a.task_id === taskId && a.question_idx === questionIndex)),
                            { task_id: taskId, question_idx: questionIndex, question, answer },
                        ]);
                    }
                }}
                isLoading={isLoading}
            />
        </Content>
    );
};

export { PlanningView, isPlanningSession };
