import React from "react";
import { Button, Statistic } from "antd";
import { BankOutlined, CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import { SubscriptionData, useSubscriptionData } from "../data/SubscriptionContext";
import { round } from "lodash";

interface SubscriptionTabProps {
    loading: boolean;
    subscriptionData: SubscriptionData | null;
    closeAppMenuFn: () => void;
}

const SubscriptionTab: React.FC<SubscriptionTabProps> = ({ loading, subscriptionData, closeAppMenuFn }) => {
    const { openPurchaseCreditsModal } = useSubscriptionData();

    const handleSubscriptionUpgrade = () => {
        solverInterfaceApiAxios
            .get("/update_subscription", { params: { return_url: window.location.href } })
            .then((response) => (window.location.href = response.data.url))
            .catch((error) => console.log(error));
    };

    const handleManageSubscription = () => {
        solverInterfaceApiAxios
            .get("/customer_portal", {
                params: {
                    return_url: window.location.href,
                },
            })
            .then((response) => (window.location.href = response.data.url))
            .catch((error) => console.log(error));
    };

    if (loading) {
        return <LoadingOutlined />;
    }

    if (!subscriptionData) {
        return null;
    }

    const buildSubscriptionButton = () => {
        if (subscriptionData.is_free) {
            return (
                <Button
                    type="primary"
                    icon={<CreditCardOutlined />}
                    className="subscription-action-button"
                    onClick={handleSubscriptionUpgrade}
                >
                    Upgrade to Pro
                </Button>
            );
        } else {
            return (
                <Button
                    type="primary"
                    icon={<BankOutlined />}
                    className="subscription-action-button"
                    onClick={handleManageSubscription}
                >
                    Manage Subscription
                </Button>
            );
        }
    };

    return (
        <>
            <div className="subscription-stats">
                <div className="subscription-stat-item">
                    <Statistic
                        title={`${subscriptionData.subscription_name} Credits`}
                        value={round(subscriptionData.remaining_subscription_usage / 100, 2)}
                        prefix={<span style={{ fontSize: "80%" }}>ⓢ</span>}
                    />
                    <div className="settings-button-container">{buildSubscriptionButton()}</div>
                </div>

                <div className={`subscription-stat-item${subscriptionData.is_free ? " disabled" : ""}`}>
                    <Statistic
                        title={
                            <span>
                                Top-up Credits
                                {subscriptionData.is_free && (
                                    <span className="pro-only-badge" style={{ marginLeft: "8px" }}>
                                        PRO
                                    </span>
                                )}
                            </span>
                        }
                        value={round(subscriptionData.remaining_usage_credits / 100, 2)}
                        prefix={<span style={{ fontSize: "80%" }}>ⓢ</span>}
                    />
                    <div className="settings-button-container">
                        <Button
                            type="primary"
                            icon={<CreditCardOutlined />}
                            className="subscription-action-button"
                            disabled={subscriptionData.is_free}
                            onClick={() => {
                                closeAppMenuFn();
                                openPurchaseCreditsModal();
                            }}
                        >
                            Purchase Credits
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionTab;
